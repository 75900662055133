export const getAddressByCep = async (cep) => {
  // Remove qualquer caractere que não seja número
  const sanitizedCep = cep.replace(/\D/g, '')

  // Verifica se o CEP tem 8 dígitos
  if (sanitizedCep.length !== 8) {
    return { error: 'CEP inválido. Certifique-se de que ele possui 8 números.' }
  }

  try {
    const response = await fetch(`https://viacep.com.br/ws/${sanitizedCep}/json/`)

    if (!response.ok) {
      return { error: 'Erro ao buscar o endereço. Tente novamente mais tarde.' }
    }

    const data = await response.json()

    if (data.erro) {
      return { error: 'CEP não encontrado.' }
    }

    return data
  } catch (error) {
    return { error: error.message || 'Erro ao buscar o endereço.' }
  }
}
