import { Button } from '../../../../components/ui/button'
import { Modal } from '../../../../components/ui/modal'
import { Select } from '../../../../components/ui/select'
import { Input } from '../../../../components/ui/input'
import { Column } from '../../../../components/layouts/columns'
// import { Table } from '../../../../components/ui/table'
import { Form } from '../../../../components/ui/form'
// import { Image } from '../../../../components/ui/image'
import { DataComTraçoParaDataComBarra } from '../../../../utils/dates'
export const ModalInviteCandidates = ({
  active = true,
  setActive,
  formContext,
  setFormContext,
  infosContext,
  HandleInviteTeams,
}) => {
  if (active) {
    return (
      <Form.Root
        action={() => {
          HandleInviteTeams({ jobInvite: formContext?.jobInvite, teamId: formContext?.teamId })
        }}
      >
        <Modal.Root active={active}>
          <Modal.Contents witdh={720}>
            <Modal.Header title={'Convidar Equipe'}>
              <Modal.HeaderClose setActive={setActive}></Modal.HeaderClose>
            </Modal.Header>

            {(infosContext?.filters?.dates || []).length > 0 ? (
              <>
                <Modal.Body>
                  <Column.Root>
                    <Column.Content>
                      <Input.Root>
                        <Input.Label
                          className="ml-3 has-text-grey-light is-size-7"
                          style={{ marginBottom: 0 }}
                          required={true}
                        >
                          Vaga
                        </Input.Label>
                        <Select.Root>
                          <Select.Content
                            color="is-grey"
                            className="is-fullwidth is-rounded is-small"
                            value={formContext}
                            setValue={setFormContext}
                            field={'jobInvite'}
                            required={true}
                          >
                            <Select.Option value={null} title={''} />
                            {/* <Select.Option value={'todos'} title={'Todos'} /> */}
                            {infosContext?.ListJobOffers.map((job) => {
                              console.log(infosContext)
                              return (
                                <Select.Option
                                  key={`job-${job.id}`}
                                  value={job.id}
                                  title={`${DataComTraçoParaDataComBarra(job.date)} - ${job.positionName}`}
                                />
                              )
                            })}
                          </Select.Content>
                        </Select.Root>
                      </Input.Root>
                    </Column.Content>
                  </Column.Root>

                  <Column.Root>
                    <Column.Content>
                      <Input.Root>
                        <Input.Label
                          className="ml-3 has-text-grey-light is-size-7"
                          style={{ marginBottom: 0 }}
                          required={true}
                        >
                          Selecione a Equipe?
                        </Input.Label>
                        <Select.Root>
                          <Select.Content
                            color="is-grey"
                            className="is-fullwidth is-rounded is-small"
                            value={formContext}
                            setValue={setFormContext}
                            field={'teamId'}
                            required={true}
                          >
                            <Select.Option value={null} title={''} />
                            {infosContext?.teams?.map((team) => {
                              return <Select.Option key={`team-${team.id}`} value={team.id} title={team.name} />
                            })}
                          </Select.Content>
                        </Select.Root>
                      </Input.Root>
                    </Column.Content>
                  </Column.Root>
                </Modal.Body>
                <Modal.Footer>
                  <Button.Root>
                    <Button.Content>
                      <Button.Bt
                        color="is-success"
                        colorText={'has-text-white'}
                        type="submit"
                        disabled={!formContext?.teamId || !formContext?.jobInvite}
                      >
                        Convidar
                      </Button.Bt>
                    </Button.Content>
                  </Button.Root>
                </Modal.Footer>
              </>
            ) : (
              <Modal.Body>
                <article className="message is-danger">
                  <div className="message-body">
                    Este eventos não possui <strong>vagas criadas</strong>, para continuar, crie algumas vagas
                  </div>
                </article>
              </Modal.Body>
            )}
          </Modal.Contents>
        </Modal.Root>
      </Form.Root>
    )
  } else {
    return <></>
  }
}
