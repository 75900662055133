import React from 'react'

import { Column } from '../../../../components/layouts/columns'
import { Input } from '../../../../components/ui/input'
import { Table } from '../../../../components/ui/table'
import { Icon } from '../../../../components/icons'
import { ConvertToTimestamp } from '../../../../utils/dates'

export const ClientsFormModalInfoCandidacyLayout = ({
  loading,
  formContext,
  setFormContext,
  options,
  disabledList,
  clientId,
}) => {
  console.log(formContext)

  return (
    <div>
      <Column.Root className="is-mobile">
        <Column.Content>
          <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
            Data
          </Input.Label>
          <Input.ReadOnly className="is-rounded" color="is-grey">
            {formContext?.date}
          </Input.ReadOnly>
        </Column.Content>
        <Column.Content>
          <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
            Vaga
          </Input.Label>
          <Input.ReadOnly className="is-rounded" color="is-grey">
            {formContext?.positionName}
          </Input.ReadOnly>
        </Column.Content>
      </Column.Root>

      <Column.Root className="is-mobile">
        <Column.Content className="has-text-centered">
          <img
            alt="photoUrl"
            src={formContext?.photoUrl}
            style={{ width: '50%', borderRadius: '50%' }}
            className="has-shadow"
          ></img>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Nome completo
            </Input.Label>
            <Input.Contents>
              <Input.ReadOnly className="is-rounded" color="is-grey">
                {formContext?.name}
              </Input.ReadOnly>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              CPF
            </Input.Label>
            <Input.Contents>
              <Input.ReadOnly className="is-rounded" color="is-grey">
                {formContext?.cpf}
              </Input.ReadOnly>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              E-mail
            </Input.Label>
            <Input.Contents>
              <Input.ReadOnly className="is-rounded" color="is-grey">
                {formContext?.email}
              </Input.ReadOnly>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Endereço
            </Input.Label>
            <Input.Contents>
              <Input.ReadOnly className="is-rounded" color="is-grey">
                {formContext?.address?.street}
                {' ' + formContext?.address?.number}
                {' ' + formContext?.address?.district}
                {' ' + formContext?.address?.city + ' - ' + formContext?.address?.state}
                {' ' + formContext?.address?.zipcode}
              </Input.ReadOnly>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
              Telefone
            </Input.Label>
            <Input.Contents>
              <Input.ReadOnly className="is-rounded" color="is-grey">
                {formContext?.contact?.phone}
              </Input.ReadOnly>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content></Column.Content>
      </Column.Root>
      <p className="subtitle mt-6 has-text-grey">
        <Icon className="mr-2">alert</Icon>
        Contato de Emergência
      </p>
      <Table.Root className="is-fullwidth">
        <Table.Head>
          <Table.Row className={'has-text-centered'}>
            <Table.Cell className={'has-text-grey'}>Nome</Table.Cell>
            <Table.Cell className={'has-text-grey'}>Parentesco</Table.Cell>
            <Table.Cell className={'has-text-grey'}>Telefone</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {(formContext?.contact?.emergencyContacts || []).map((contact, index) => {
            return (
              <Table.Row key={String(index) + 'emergencyContacts'}>
                <Table.Cell>{contact.name}</Table.Cell>
                <Table.Cell>{contact.kinship}</Table.Cell>
                <Table.Cell>{contact.phone}</Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table.Root>

      <p className="subtitle mt-6 has-text-grey">
        <Icon className="mr-2">history</Icon>
        Marcações de Horários
      </p>
      <Table.Root className="is-fullwidth">
        <Table.Head>
          <Table.Row>
            <Table.Cell>Data</Table.Cell>
            <Table.Cell>Comentário</Table.Cell>
            <Table.Cell>Registrado por</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          {(formContext?.workingHours || [])
            .sort((a, b) => ConvertToTimestamp(a.time) - ConvertToTimestamp(b.time))
            .map((contact, index) => {
              return (
                <Table.Row key={String(index) + 'workTime'}>
                  <Table.Cell>{contact.time}</Table.Cell>
                  <Table.Cell>{contact.comment}</Table.Cell>
                  <Table.Cell>{contact.registerBy}</Table.Cell>
                </Table.Row>
              )
            })}
        </Table.Body>
      </Table.Root>

      <p className="subtitle mt-6 has-text-grey">
        <Icon className="mr-2">bill</Icon>
        Histórico Financeiro
      </p>
      <Table.Root className="is-fullwidth">
        <Table.Head>
          <Table.Row>
            <Table.Cell>Descrição</Table.Cell>
            <Table.Cell>Tipo</Table.Cell>
            <Table.Cell className="has-text-right">Valor</Table.Cell>
          </Table.Row>
        </Table.Head>
        <Table.Body>
          <Table.Row key={'emergencyContacts0001'}>
            <Table.Cell>Cachê</Table.Cell>
            <Table.Cell>Crédito</Table.Cell>
            <Table.Cell className="has-text-right">+ R$ {formContext?.valueCache || 0}</Table.Cell>
          </Table.Row>

          {(formContext?.financialHistory || []).map((financial, index) => {
            return (
              <Table.Row key={String(index) + 'workTime'}>
                <Table.Cell>{financial.desc}</Table.Cell>
                <Table.Cell>{financial.type === 'D' ? 'Desconto' : 'Crédito'}</Table.Cell>
                <Table.Cell className="has-text-right">
                  {financial.type === 'D' ? '-' : '+'} R$ {financial.value}
                </Table.Cell>
              </Table.Row>
            )
          })}
        </Table.Body>
      </Table.Root>
    </div>
  )
}
