import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../../context/ContextGlobal'
import { TryCatch } from '../../../../../utils/general'
import {
  addSubDocument,
  deleteSubDocument,
  getSubDocuments,
  getSubDocumentsWithQuerys,
} from '../../../../../firebase/firestore'
import { getAddressByCep } from '../../../../../utils/consultingCEP'

export const useClientCitys = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    // setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const citys = dataContext?.citys || {}
  const [search, setSearch] = useState('')
  // const navigate = useNavigate();
  const clientId = user.client.id

  const getDatas = async () => {
    const getCitys = await getSubDocuments('clientes', clientId, 'citys')

    // salva todas as informações coletadas no context.
    setDataContext({ citys: getCitys })
  }

  const getCepInfo = async () => {
    const cep = formContext?.cep || null
    if (!cep) {
      return setAlerts((prev) => [
        ...prev,
        { type: 'error', title: 'Cep Obrigatório', msg: 'Digite um cep válido para continuar' },
      ])
    }

    const infosCep = await getAddressByCep(cep)

    if (infosCep?.error) {
      setFormContext((prev) => ({
        ...prev,
        responseCEP: [],
      }))
      return setAlerts((prev) => [...prev, { type: 'error', title: 'Erro ao buscar cep', msg: infosCep?.error }])
    }

    setFormContext((prev) => ({
      ...prev,
      responseCEP: [{ ...infosCep }],
    }))
  }

  const addCity = async (value) => {
    const city = value.localidade
    const uf = value.uf
    const query = [
      { field: 'city', operator: '==', value: city },
      { field: 'uf', operator: '==', value: uf },
    ]
    const exist = await getSubDocumentsWithQuerys('clientes', user.client.id, 'citys', query)

    if (Array.isArray(exist) && exist.length > 0) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Cidade Existente',
          msg: `A Cidade "${city}" ja foi adicionada na sua base de dados.`,
        },
      ])
    }

    await addSubDocument('clientes', user.client.id, 'citys', { city, uf })
    FetchGetDatas()
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Cidade Adicionada',
        msg: `A Cidade "${city}" foi adicionada com sucesso na sua base de dados.`,
      },
    ])
  }
  const getInfos = async () => {}

  const deleteCity = async (id) => {
    if (!user?.user?.permissions?.city_delete) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Permissão Negada',
          msg: 'Você não possui permissão para apagar Cidades na sua base de dados',
        },
      ])
    }

    await deleteSubDocument('clientes', clientId, 'citys', id)
    await FetchGetDatas()
    await FetchGetInfos()
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Cidade Excluída',
        msg: 'A Cidade Foi excluída com sucesso da sua base de dados',
      },
    ])
  }

  const FetchGetDatas = async () => {
    await TryCatch(getDatas, setLoading, setAlerts)
  }
  const FetchGetInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }
  const HandleDeleteCity = async (id) => {
    await TryCatch(
      async () => {
        deleteCity(id)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleGetCepInfo = async () => {
    await TryCatch(getCepInfo, setLoading, setAlerts)
  }

  const HandleAddCity = async (city) => {
    await TryCatch(
      async () => {
        addCity(city)
      },
      setLoading,
      setAlerts,
    )
  }

  const dataFiltered =
    search !== ''
      ? Object.values(citys).filter((value) => {
          const searchString = `${value.city}|${value.uf}`.toLowerCase()
          return searchString.includes(search.toLowerCase())
        })
      : Object.values(citys)

  useEffect(() => {
    FetchGetDatas()
    FetchGetInfos()
  }, [])

  return {
    loading,
    search,
    setSearch,
    dataFiltered,
    dataContext,
    setDataContext,
    infosContext,
    FetchGetDatas,
    FetchGetInfos,
    HandleDeleteCity,
    formContext,
    setFormContext,
    HandleGetCepInfo,
    HandleAddCity,
  }
}
