import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
import { useClientEventsStaff } from './_hooks/useClientEventsStaff'
import { Button } from '../../../components/ui/button'
import { Input } from '../../../components/ui/input'
import { Icon } from '../../../components/icons'
import { Table } from '../../../components/ui/table'
import { DataComTraçoParaDataComBarra } from '../../../utils/dates'
import { PaginationFunctional } from '../../../components/ui/pagination/PaginationFunctional'
import { StatusCandidate, statusStaff } from './_utils/statusCandidate'
import { Select } from '../../../components/ui/select'
import { Checkbox } from '../../../components/ui/checkbox'
import { ActionsOfCandidates } from './_utils/actionsOfCandidates'
import { Form } from '../../../components/ui/form'
import { ModalInviteCandidates } from './_modal/modalInviteCandidates'
import { ModalInfoCandidacy } from './_modal/modalInfoCandidacy'
import { ModalUploadPayment } from './_modal/modalUploadPayment'
import { ModalRegisterWorkingHours } from './_modal/modalRegisterWorkingHours'
import { ModalAddCandidates } from './_modal/modalAddCandidates'
// import { Button } from '../../../components/ui/button'
// import { Path } from '../../../router/paths'

export const ClientEventsStaff = () => {
  const {
    page,
    setPage,
    loading,
    filter,
    setFilter,
    checks,
    setChecks,
    checkAll,
    setCheckall,
    infosContext,
    dataContext,
    formContext,
    setFormContext,
    FetchQueryDatas,
    FetchGetInfos,
    FetchGetdatas,
    FetchClearQueryData,
    ActionForButtonsCandidates,
    ActionForButtonsMultiplesCandidates,
    modalInvite,
    setModalInvite,
    modalInfoCandidacy,
    setModalInfoCandidacy,
    modalUploadPayments,
    setModalUploadPayments,
    ActionForModalInfoColaborator,
    HandleUpdateValuesInfoCandidacy,
    exportAllData,
    criar1000Candidaturas,
    teamsList,
    HandleInviteTeams,
    HandleExportExcelPayment,
    HandleReadSheetPayment,
    modalRegisterWorkingHours,
    setModalRegisterWorkingHours,
    HandleAddWorkingHours,
    HandleDeleteWorkingHours,
    HandleExportAllWorkingTimeRegister,
    modalAdd,
    setModalAdd,
    HandleGetDataFavorites,
    searchFavorite,
    setSearchFavorite,
    HandleAddCandidacy,
  } = useClientEventsStaff()

  return (
    <div>
      <ModalInfoCandidacy
        setActive={setModalInfoCandidacy}
        active={modalInfoCandidacy}
        values={formContext}
        actions={ActionForModalInfoColaborator()}
      ></ModalInfoCandidacy>
      <ModalInviteCandidates
        setActive={setModalInvite}
        active={modalInvite}
        formContext={formContext}
        setFormContext={setFormContext}
        infosContext={infosContext}
        HandleInviteTeams={HandleInviteTeams}
      ></ModalInviteCandidates>
      <ModalAddCandidates
        setActive={setModalAdd}
        active={modalAdd}
        formContext={formContext}
        setFormContext={setFormContext}
        search={searchFavorite}
        setSearch={setSearchFavorite}
        infosContext={infosContext}
        dataContext={dataContext}
        loading={loading}
        actions={{
          searchFavorite: () => {
            HandleGetDataFavorites()
          },
          add: async (values) => {
            await HandleAddCandidacy(values)
          },
        }}
      ></ModalAddCandidates>
      <ModalRegisterWorkingHours
        setActive={() => {
          setModalRegisterWorkingHours(false)
          setFormContext((prev) => ({ ...prev, CandidacyRegisterWorkingHours: '' }))
        }}
        active={modalRegisterWorkingHours}
        formContext={formContext}
        setFormContext={setFormContext}
        loading={loading}
        infosContext={infosContext}
        teamsList={teamsList}
        actions={{
          submit: () => {
            HandleAddWorkingHours()
          },
          delete: (values) => {
            HandleDeleteWorkingHours(values)
          },
        }}
      ></ModalRegisterWorkingHours>
      <ModalUploadPayment
        setActive={setModalUploadPayments}
        active={modalUploadPayments}
        formContext={formContext}
        setFormContext={setFormContext}
        loading={loading}
        actions={{
          submit: () => {
            HandleReadSheetPayment()
          },
          download: () => {
            HandleExportExcelPayment()
          },
        }}
      ></ModalUploadPayment>

      {(!modalInfoCandidacy || !modalInvite || !modalRegisterWorkingHours || !modalUploadPayments) && (
        <>
          <Page.Section className="is-fullheight-with-navbar mt-6 mx-3" style={{ minHeight: '70vh' }}>
            <Column.Root>
              {/* Titulo da pagina */}
              <Column.Content>
                <p className="subtitle is-size-4">
                  <Icon size={30} className={'mr-3'}>
                    users
                  </Icon>
                  Staff
                </p>
              </Column.Content>

              {/* Botões superiores */}
              <Column.Content className="has-text-right">
                <Button.Root align="right">
                  <Button.Content>
                    <Button.Bt
                      className=""
                      color="is-light"
                      loading={loading}
                      title={'Exportar Planilha'}
                      action={() => {
                        criar1000Candidaturas()
                      }}
                    >
                      <Icon size={25} margin={-5}>
                        users
                      </Icon>
                    </Button.Bt>
                  </Button.Content>
                  <Button.Content>
                    <Button.Bt
                      color="is-dark"
                      colorText="has-text-white"
                      action={async () => {
                        FetchGetInfos()
                        FetchGetdatas()
                      }}
                      loading={loading}
                    >
                      <Icon size={20} margin={-1}>
                        sync
                      </Icon>
                    </Button.Bt>
                  </Button.Content>
                  <Button.Content>
                    <Button.Bt
                      className=""
                      color="is-light"
                      loading={loading}
                      title={'Adicionar Marcação de pontos'}
                      action={() => {
                        setModalRegisterWorkingHours(true)
                        setFormContext((prev) => ({ ...prev, CandidacyRegisterWorkingHours: 'all' }))
                      }}
                      isHidden={formContext.statusEvent === 'closed'}
                    >
                      <Icon size={25} margin={-5} className="mr-2">
                        history
                      </Icon>
                      +
                    </Button.Bt>
                  </Button.Content>
                  <Button.Content>
                    <Button.Bt
                      className=""
                      color="is-light"
                      colorText="has-text-success"
                      loading={loading}
                      title={'Exportar Planilha de Pontos'}
                      action={() => {
                        HandleExportAllWorkingTimeRegister()
                      }}
                    >
                      <Icon size={25} margin={-5} className="mr-2">
                        history
                      </Icon>
                      <Icon size={25} margin={-5}>
                        checkin
                      </Icon>
                    </Button.Bt>
                  </Button.Content>

                  <Button.Content>
                    <Button.Bt
                      className=""
                      color="is-light"
                      colorText="has-text-success"
                      loading={loading}
                      title={'Exportar Staff'}
                      action={() => {
                        exportAllData()
                      }}
                    >
                      <Icon size={25} margin={-5} className="mr-2">
                        users
                      </Icon>
                      <Icon size={25} margin={-5}>
                        checkin
                      </Icon>
                    </Button.Bt>
                  </Button.Content>
                  <Button.Content>
                    <Button.Bt
                      className=""
                      color="is-success"
                      colorText="has-text-white"
                      loading={loading}
                      title={'Enviar Convite'}
                      action={() => {
                        setModalInvite(true)
                      }}
                      isHidden={formContext.statusEvent === 'closed'}
                    >
                      <Icon size={25} margin={-5} className={'mr-1'}>
                        invite
                      </Icon>
                      <Icon size={20} margin={-5}>
                        users
                      </Icon>
                    </Button.Bt>
                  </Button.Content>
                  <Button.Content>
                    <Button.Bt
                      className=""
                      color="is-success"
                      colorText="has-text-white"
                      loading={loading}
                      title={'Enviar Convite'}
                      action={() => {
                        setModalAdd(true)
                      }}
                      isHidden={formContext.statusEvent === 'closed'}
                    >
                      +<Icon size={18}>user</Icon>
                    </Button.Bt>
                  </Button.Content>
                  <Button.Content>
                    <Button.Bt
                      className=""
                      color="is-light"
                      loading={loading}
                      title="Importar Pagamentos"
                      action={() => {
                        setModalUploadPayments(true)
                      }}
                      isHidden={formContext.statusEvent === 'open'}
                    >
                      <Icon size={25} margin={-5} className="mr-2">
                        bill
                      </Icon>
                      <Icon size={25} margin={-5}>
                        checkout
                      </Icon>
                    </Button.Bt>
                  </Button.Content>
                </Button.Root>
              </Column.Content>
            </Column.Root>

            {dataContext?.staff ? (
              <>
                <Form.Root
                  action={() => {
                    FetchGetdatas()
                    FetchGetInfos()
                  }}
                >
                  {/* Pesquisar */}
                  <Column.Root className="is-mobile">
                    <Column.Content>
                      <Input.Root>
                        <Input.Contents alignIcon={'left'}>
                          <Input.Prompt
                            value={filter}
                            setValue={setFilter}
                            field={'search'}
                            placeholder="Persquisar..."
                          ></Input.Prompt>
                          <Input.Icon align="is-left">
                            <Icon size={20}>search</Icon>
                          </Input.Icon>
                        </Input.Contents>
                      </Input.Root>
                    </Column.Content>
                  </Column.Root>

                  {/* Filtros da pagina */}
                  <div className="box my-2">
                    <Input.Label className="has-text-grey mb-2" style={{ marginBottom: 0 }}>
                      Filtros
                    </Input.Label>
                    <Column.Root className="ml-2">
                      <Column.Content>
                        <Input.Root>
                          <Input.Label className="ml-3 has-text-grey-light is-size-7" style={{ marginBottom: 0 }}>
                            Data
                          </Input.Label>
                          <Select.Root>
                            <Select.Content
                              color="is-grey"
                              className="is-fullwidth is-rounded is-small"
                              value={filter}
                              setValue={setFilter}
                              field={'date'}
                            >
                              <Select.Option value={'todos'} title={'Todos'} />
                              {infosContext?.filters?.dates.map((date) => {
                                return (
                                  <Select.Option
                                    key={`date-${date}`}
                                    value={date}
                                    title={DataComTraçoParaDataComBarra(date)}
                                  />
                                )
                              })}
                            </Select.Content>
                          </Select.Root>
                        </Input.Root>
                      </Column.Content>
                      <Column.Content>
                        <Input.Root>
                          <Input.Label className="ml-3 has-text-grey-light is-size-7" style={{ marginBottom: 0 }}>
                            Vaga
                          </Input.Label>
                          <Select.Root>
                            <Select.Content
                              color="is-grey"
                              className="is-fullwidth is-rounded is-small"
                              value={filter}
                              setValue={setFilter}
                              field={'idPosition'}
                            >
                              <Select.Option value={'todos'} title={'Todos'} />
                              {infosContext?.filters?.positions.map((position) => {
                                return (
                                  <Select.Option
                                    key={`position-${position}`}
                                    value={position}
                                    title={infosContext?.positionsById[position]}
                                  />
                                )
                              })}
                            </Select.Content>
                          </Select.Root>
                        </Input.Root>
                      </Column.Content>
                      <Column.Content>
                        <Input.Root>
                          <Input.Label className="ml-3 has-text-grey-light is-size-7" style={{ marginBottom: 0 }}>
                            Status
                          </Input.Label>
                          <Select.Root>
                            <Select.Content
                              color="is-grey"
                              className="is-fullwidth is-rounded is-small"
                              value={filter}
                              setValue={setFilter}
                              field={'status'}
                            >
                              <Select.Option value={'todos'} title={'Todos'} />
                              {Object.keys(statusStaff).map((status) => {
                                return (
                                  <Select.Option
                                    key={`status-${status}`}
                                    value={status}
                                    title={`${status} - ${statusStaff[status].status}`}
                                  />
                                )
                              })}
                            </Select.Content>
                          </Select.Root>
                        </Input.Root>
                      </Column.Content>
                      <Column.Content size="is-2">
                        <Button.Root align="is-left">
                          <Button.Content>
                            <Button.Bt
                              className="mt-4 is-rounded"
                              color="is-light"
                              size={'is-small'}
                              loading={loading}
                              title={'Enviar Convite'}
                              type="button"
                              action={() => {
                                FetchClearQueryData()
                              }}
                            >
                              Limpar
                            </Button.Bt>
                          </Button.Content>

                          <Button.Content>
                            <Button.Bt
                              className="mt-4 is-rounded"
                              color="is-dark"
                              size={'is-small'}
                              loading={loading}
                              title={'Enviar Convite'}
                              action={() => {
                                FetchQueryDatas()
                              }}
                            >
                              Filtrar
                            </Button.Bt>
                          </Button.Content>
                        </Button.Root>
                      </Column.Content>
                    </Column.Root>
                  </div>
                </Form.Root>

                {formContext?.statusEvent === 'open' && (
                  <>
                    <Column.Root className={'mt-5'}>
                      {/* Ações quando checkbox é chegacado */}
                      {Object.values(checks).filter((check) => check === true).length > 0 ? (
                        <Column.Content className="box ">
                          <Input.Label className="has-text-grey-light is-size-7 mb-2" style={{ marginBottom: 0 }}>
                            Colaboradores selecionados: [{' '}
                            {Object.values(checks).filter((check) => check === true).length} ]
                          </Input.Label>
                          <ActionsOfCandidates
                            loading={loading}
                            status={Number(filter?.status)}
                            align="left"
                            actions={ActionForButtonsMultiplesCandidates(checks)}
                          ></ActionsOfCandidates>
                        </Column.Content>
                      ) : (
                        <Column.Content></Column.Content>
                      )}

                      {/* Contagem de candidaturas */}
                      <Column.Content className="has-text-right">
                        <p>
                          <Icon size={20} className={'mx-2'}>
                            users
                          </Icon>
                          {dataContext?.staff?.pagination.totalItems}
                        </p>
                      </Column.Content>
                    </Column.Root>
                  </>
                )}

                {/* Lista de Staff */}
                {dataContext?.staff?.data.length > 0 ? (
                  <Column.Root className="is-mobile box my-2">
                    <Column.Content>
                      <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
                        <Table.Head>
                          <Table.Row key={'table_events_head'}>
                            <Table.Cell size={20}>
                              <Checkbox.Root>
                                {filter?.filtered && (
                                  <Checkbox.Content value={checkAll} setValue={setCheckall}></Checkbox.Content>
                                )}
                              </Checkbox.Root>
                            </Table.Cell>
                            <Table.Cell>Data</Table.Cell>
                            <Table.Cell>Vaga</Table.Cell>
                            <Table.Cell>Nome</Table.Cell>
                            <Table.Cell>CPF</Table.Cell>
                            <Table.Cell>Status</Table.Cell>
                            {formContext?.statusEvent === 'open' && <Table.Cell size={200}>Ações</Table.Cell>}
                          </Table.Row>
                        </Table.Head>
                        <Table.Body>
                          {dataContext?.staff?.data.map((value) => {
                            console.log(value)
                            return (
                              <>
                                <Table.Row
                                  key={value.id}
                                  onclick={() => {
                                    HandleUpdateValuesInfoCandidacy(value)
                                  }}
                                >
                                  <Table.Cell className="is-size-7 is-vcentered">
                                    <Checkbox.Root>
                                      {filter?.filtered && Number(value.status) === Number(filter?.status) && (
                                        <Checkbox.Content
                                          key={`check-${value.id}`}
                                          value={checks}
                                          setValue={setChecks}
                                          field={value.id}
                                        ></Checkbox.Content>
                                      )}
                                    </Checkbox.Root>
                                  </Table.Cell>
                                  <Table.Cell className="is-size-7 is-vcentered">
                                    {DataComTraçoParaDataComBarra(infosContext?.jobOffers[value.idJobOffers].date)}
                                  </Table.Cell>
                                  <Table.Cell className="is-size-7 is-vcentered">
                                    {infosContext?.jobOffers[value.idJobOffers].positionName}
                                  </Table.Cell>
                                  <Table.Cell className="is-size-7 is-vcentered">{value.name}</Table.Cell>
                                  <Table.Cell className="is-size-7 is-vcentered">{value.cpf}</Table.Cell>
                                  <Table.Cell className="is-size-7 is-vcentered">
                                    {StatusCandidate(value.status)}
                                  </Table.Cell>
                                  {formContext?.statusEvent === 'open' && (
                                    <Table.Cell className="is-vcentered">
                                      <div className="mt-2">
                                        <ActionsOfCandidates
                                          loading={loading}
                                          status={Number(value.status)}
                                          align="centered"
                                          actions={ActionForButtonsCandidates(value)}
                                        ></ActionsOfCandidates>
                                      </div>
                                    </Table.Cell>
                                  )}
                                </Table.Row>
                              </>
                            )
                          })}
                        </Table.Body>
                      </Table.Root>
                    </Column.Content>
                  </Column.Root>
                ) : (
                  <p className="has-text-centered mt-6">Ops, Nenhuma candidatura encontrada</p>
                )}
              </>
            ) : (
              <Column.Root>
                <Column.Content></Column.Content>
                <Column.Content>
                  <Icon>loading</Icon>
                </Column.Content>
                <Column.Content></Column.Content>
              </Column.Root>
            )}
          </Page.Section>
          <PaginationFunctional
            paginationData={dataContext?.staff?.pagination}
            valuePage={page}
            setValuePage={setPage}
            setFetchData={() => {
              FetchGetdatas()
              FetchGetInfos()
            }}
          ></PaginationFunctional>
        </>
      )}
    </div>
  )
}
