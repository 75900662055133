import { Column } from '../../../../components/layouts/columns'
import { Input } from '../../../../components/ui/input'
import { useValidateCollaboratorModalForm } from '../_hooks/useValidateCollaboratorModalForm'

export const ValidateCollaboratorModalForm = () => {
  const { loading, formContext, handleUpdateEditReason, handleUpdateAllEditReasons } =
    useValidateCollaboratorModalForm()

  return (
    <div>
      {formContext?.inEdition &&
        Object.keys(formContext?.inEdition)?.map((item, index) => {
          // Não renderiza os emergencyContacts agora, vamos deixá-los por último
          if (item !== 'emergencyContacts') {
            return (
              <Column.Root className="is-mobile" key={index}>
                <Column.Content className="is-half">
                  <div className="field">
                    <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }}>
                      {formContext.inEdition[item].title}
                    </Input.Label>
                    <div className="is-flex w-100 gap-2">
                      <Input.ReadOnly className="is-rounded" color="is-grey">
                        {formContext.inEdition[item].fieldValue}
                      </Input.ReadOnly>
                    </div>
                  </div>
                </Column.Content>
                <Column.Content className="is-half">
                  <div className="field">
                    <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }}>
                      Motivo
                    </Input.Label>
                    <div className="is-flex w-100 gap-2">
                      <input
                        id={`item-${index}`}
                        className={`input is-grey is-rounded ${loading ? 'is-loading' : ''}`}
                        type="text"
                        value={formContext.inEdition[item].reason || ''}
                        onChange={(e) => handleUpdateEditReason(item, e)} // Atualiza o campo específico do objeto
                        required={true}
                      />
                    </div>
                  </div>
                </Column.Content>
              </Column.Root>
            )
          }
          return null // Não renderiza nada se for emergencyContacts agora
        })}

      {/* Renderiza os contatos de emergência por último */}
      {formContext?.inEdition?.emergencyContacts && Array.isArray(formContext?.inEdition?.emergencyContacts) && (
        <div>
          {formContext?.inEdition?.emergencyContacts.map((contact, contactIndex) => (
            <div className="box mb-4" key={contactIndex}>
              <p className="subtitle is-6 has-text-grey mb-2">Contato de emergência {contactIndex + 1}</p>
              {/* Itera sobre cada chave do contato (ex: 'parentesco', 'celular') */}
              {Object.keys(contact).map((field, fieldIndex) => {
                const value = contact[field]

                return (
                  <div key={`${contactIndex}-${fieldIndex}`}>
                    <Column.Root className="is-mobile">
                      <Column.Content className="is-half">
                        <div className="field">
                          <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }}>
                            {value.title}
                          </Input.Label>
                          <div className="is-flex w-100 gap-2">
                            <Input.ReadOnly className="is-rounded" color="is-grey">
                              {value.fieldValue}
                            </Input.ReadOnly>
                          </div>
                        </div>
                      </Column.Content>
                      <Column.Content className="is-half">
                        <div className="field">
                          <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }}>
                            Motivo
                          </Input.Label>
                          <div className="is-flex w-100 gap-2">
                            <input
                              id={`emergency-contact-${contactIndex}-${field}`}
                              className={`input is-grey is-rounded ${loading ? 'is-loading' : ''}`}
                              type="text"
                              value={value?.reason || ''} // Acessa diretamente a razão de cada campo de contato
                              onChange={(e) => handleUpdateEditReason(field, e, contactIndex)} // Passa o campo, evento e índice do contato
                              required={true}
                            />
                          </div>
                        </div>
                      </Column.Content>
                    </Column.Root>
                  </div>
                )
              })}
            </div>
          ))}
        </div>
      )}

      {/* Motivo geral para todos os contatos de emergência e itens do objeto */}
      <Column.Root className="is-mobile">
        <Column.Content className="is-full">
          <div className="field">
            <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }}>
              Motivo Geral
            </Input.Label>
            <div className="is-flex w-100 gap-2">
              <input
                id="general-reason"
                className={`input is-grey is-rounded ${loading ? 'is-loading' : ''}`}
                type="text"
                onChange={(e) => handleUpdateAllEditReasons(e)} // Atualiza o motivo geral
              />
              <Input.Error>(se aplica para todos os campos não preenchidos)</Input.Error>
            </div>
          </div>
        </Column.Content>
      </Column.Root>
    </div>
  )
}
