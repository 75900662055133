/**
 * Função para retornar o time atual neste formato: "day/month/year hours:minutes"
 *
 * @returns {string} - Time Atual"day/month/year hours:minutes".
 */

export const DateTimeNow = () => {
  const now = new Date()

  const day = String(now.getDate()).padStart(2, '0')
  const month = String(now.getMonth() + 1).padStart(2, '0') // Os meses são baseados em zero
  const year = String(now.getFullYear()) // Pega os últimos dois dígitos do ano
  const hours = String(now.getHours()).padStart(2, '0')
  const minutes = String(now.getMinutes()).padStart(2, '0')

  return `${day}/${month}/${year} ${hours}:${minutes}`
}

export const DateNow = () => {
  const now = new Date()

  const day = String(now.getDate()).padStart(2, '0')
  const month = String(now.getMonth() + 1).padStart(2, '0') // Os meses são baseados em zero
  const year = String(now.getFullYear())

  return `${year}-${month}-${day}`
}

export const TimestampNow = () => {
  return Date.now()
}

export const SomarDiasAData = (dias, data) => {
  const dataInicial = new Date(data)
  dataInicial.setDate(dataInicial.getDate() + Number(dias))
  return dataInicial.toISOString().split('T')[0]
}

export const DataComTraçoParaDataComBarra = (data) => {
  const dataSplit = String(data).split('-')
  console.log('dataSplit: ', dataSplit)
  return `${dataSplit[2]}/${dataSplit[1]}/${dataSplit[0]}`
}

/**
 * Converte uma data no formato "dd/MM/yyyy HH:mm" ou "dd/MM/yyyy" para timestamp.
 *
 * @param {string} dateStr - A data no formato "dd/MM/yyyy HH:mm" ou "dd/MM/yyyy".
 * @returns {number} - O timestamp correspondente à data em segundos desde a época Unix.
 * @throws {Error} - Lança um erro se a data estiver em formato inválido.
 */
export const ConvertToTimestamp = (dateStr) => {
  if (!dateStr || typeof dateStr !== 'string') {
    throw new Error('O parâmetro deve ser uma string válida.')
  }

  let day, month, year

  const dateParts = dateStr.split(' ')
  if (dateParts[0].includes('-')) {
    day = dateParts[0].split('-')[2]
    month = dateParts[0].split('-')[1]
    year = dateParts[0].split('-')[0]
  } else {
    day = dateParts[0].split('/')[0]
    month = dateParts[0].split('/')[1]
    year = dateParts[0].split('/')[2]
  }
  if (!day || !month || !year) {
    throw new Error('Data inválida. Certifique-se de usar o formato "dd/MM/yyyy".')
  }

  let formattedDate

  if (dateParts.length > 1) {
    const [hours, minutes] = dateParts[1].split(':').map(Number)

    if (isNaN(hours) || isNaN(minutes)) {
      throw new Error('Horário inválido. Certifique-se de usar o formato "HH:mm".')
    }

    formattedDate = new Date(year, month - 1, day, hours, minutes)
  } else {
    formattedDate = new Date(year, month - 1, day)
  }

  if (isNaN(formattedDate.getTime())) {
    throw new Error('Data inválida. Não foi possível criar um objeto Date.')
  }

  // Retorna o timestamp em segundos
  return Math.floor(formattedDate.getTime() / 1000)
}
