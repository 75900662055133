import React from 'react'

import { Column } from '../../../../components/layouts/columns'
import { Button } from '../../../../components/ui/button'
import { Input } from '../../../../components/ui/input'
import { Select } from '../../../../components/ui/select'
import { Path } from '../../../../router/paths'
import { Table } from '../../../../components/ui/table'
import { Icon } from '../../../../components/icons'
import { DataComTraçoParaDataComBarra, SomarDiasAData } from '../../../../utils/dates'
import { useFormClients } from '../_hooks'
import { useGlobalContext } from '../../../../context/ContextGlobal'

export const ClientsLayout = ({ values, setValues, options, disabledList, type = 'add', clientId }) => {
  const {
    search,
    setSearch,
    infoClient,
    setInfoClient,
    endereco,
    setEndereco,
    listplanos,
    planos,
    setPlanos,
    assinaturas,
    setAssinaturas,
    responsavel,
    setResponsavel,
    AddPlanInList,
    DeletePlansOfList,
    statusUserAdmin,
  } = useFormClients(values, setValues, options, clientId)

  const { loading } = useGlobalContext()

  return (
    <div>
      <Column.Root>
        <Column.Content>
          <Input.Root className="mb-3">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              CNPJ/CPF{' '}
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={infoClient}
                setValue={setInfoClient}
                field={'cpfCnpj'}
                required={true}
                disabled={disabledList?.cpfCnpj}
                maskValue={'cpfCnpj'}
                maskSetValue={'cpfCnpj'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  Razão Social{' '}
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    className="is-rounded"
                    value={infoClient}
                    setValue={setInfoClient}
                    field={'razaoSocial'}
                    required={true}
                    disabled={disabledList?.razaoSocial}
                    maskValue={'title'}
                    maskSetValue={'lower'}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  Nome Fantasia
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    className="is-rounded"
                    value={infoClient}
                    setValue={setInfoClient}
                    field={'nomeFantasia'}
                    required={true}
                    disabled={disabledList?.nomeFantasia}
                    maskValue={'title'}
                    maskSetValue={'lower'}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
          </Column.Root>

          <Column.Root>
            <Column.Content>
              <Input.Root className="">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  Cep
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    className="is-rounded"
                    value={endereco}
                    setValue={setEndereco}
                    field={'cep'}
                    required={true}
                    disabled={disabledList?.cep}
                    maskValue={'title'}
                    maskSetValue={'lower'}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
              <Input.Error>
                {endereco?.cep
                  ? endereco?.error
                    ? endereco?.error
                    : ''
                  : 'Digite o cep para preencher automaticamente as informações de endereço'}
              </Input.Error>
            </Column.Content>

            <Column.Content>
              <Input.Root className="">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  Logradouro
                </Input.Label>
                <Input.Contents>
                  {endereco?.manualCompleted ? (
                    <Input.Prompt
                      className="is-rounded"
                      value={endereco}
                      setValue={setEndereco}
                      field={'logradouro'}
                      required={true}
                      disabled={disabledList?.logradouro}
                      maskValue={'title'}
                      maskSetValue={'lower'}
                    ></Input.Prompt>
                  ) : (
                    <Input.ReadOnly className="is-rounded">{endereco.logradouro}</Input.ReadOnly>
                  )}
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content size="is-2">
              <Input.Root className="">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  Número
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    className="is-rounded"
                    value={endereco}
                    setValue={setEndereco}
                    field={'numero'}
                    required={true}
                    disabled={disabledList?.numero}
                  ></Input.Prompt>
                </Input.Contents>
                <Input.Error>{endereco?.cep && !endereco?.numero ? 'Campo obrigatório' : ''}</Input.Error>
              </Input.Root>
            </Column.Content>
            <Column.Content>
              <Input.Root className="">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  Complemento
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    className="is-rounded"
                    value={endereco}
                    setValue={setEndereco}
                    field={'complemento'}
                    required={true}
                    disabled={disabledList?.complemento}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mb-6">
            <Column.Content>
              <Input.Root className="">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  Bairro
                </Input.Label>
                <Input.Contents>
                  {endereco?.manualCompleted ? (
                    <Input.Prompt
                      className="is-rounded"
                      value={endereco}
                      setValue={setEndereco}
                      field={'bairro'}
                      required={true}
                      disabled={disabledList?.logradouro}
                      maskValue={'title'}
                      maskSetValue={'lower'}
                    ></Input.Prompt>
                  ) : (
                    <Input.ReadOnly className="is-rounded">{endereco.bairro}</Input.ReadOnly>
                  )}
                </Input.Contents>
              </Input.Root>
            </Column.Content>

            <Column.Content>
              <Input.Root className="">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  Municipio
                </Input.Label>
                <Input.Contents>
                  {endereco?.manualCompleted ? (
                    <Input.Prompt
                      className="is-rounded"
                      value={endereco}
                      setValue={setEndereco}
                      field={'municipio'}
                      required={true}
                      disabled={disabledList?.logradouro}
                      maskValue={'title'}
                      maskSetValue={'lower'}
                    ></Input.Prompt>
                  ) : (
                    <Input.ReadOnly className="is-rounded">{endereco.municipio}</Input.ReadOnly>
                  )}
                </Input.Contents>
              </Input.Root>
            </Column.Content>

            <Column.Content size="is-2">
              <Input.Root className="">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  UF
                </Input.Label>
                {endereco?.manualCompleted ? (
                  <Select.Root>
                    <Select.Content
                      color="is-grey"
                      className="is-fullwidth is-rounded"
                      value={endereco}
                      setValue={setEndereco}
                      field={'uf'}
                      disabled={disabledList?.uf}
                      required={true}
                    >
                      <Select.Option value={null} title={''} />
                      <Select.Option value={'AC'} title={'AC'} />
                      <Select.Option value={'AL'} title={'AL'} />
                      <Select.Option value={'AP'} title={'AP'} />
                      <Select.Option value={'AM'} title={'AM'} />
                      <Select.Option value={'BA'} title={'BA'} />
                      <Select.Option value={'CE'} title={'CE'} />
                      <Select.Option value={'DF'} title={'DF'} />
                      <Select.Option value={'ES'} title={'ES'} />
                      <Select.Option value={'GO'} title={'GO'} />
                      <Select.Option value={'MA'} title={'MA'} />
                      <Select.Option value={'MT'} title={'MT'} />
                      <Select.Option value={'MS'} title={'MS'} />
                      <Select.Option value={'MG'} title={'MG'} />
                      <Select.Option value={'PA'} title={'PA'} />
                      <Select.Option value={'PB'} title={'PB'} />
                      <Select.Option value={'PR'} title={'PR'} />
                      <Select.Option value={'PE'} title={'PE'} />
                      <Select.Option value={'PI'} title={'PI'} />
                      <Select.Option value={'RJ'} title={'RJ'} />
                      <Select.Option value={'RN'} title={'RN'} />
                      <Select.Option value={'RS'} title={'RS'} />
                      <Select.Option value={'RO'} title={'RO'} />
                      <Select.Option value={'RR'} title={'RR'} />
                      <Select.Option value={'SC'} title={'SC'} />
                      <Select.Option value={'SP'} title={'SP'} />
                      <Select.Option value={'SE'} title={'SE'} />
                      <Select.Option value={'TO'} title={'TO'} />
                    </Select.Content>
                  </Select.Root>
                ) : (
                  <Input.ReadOnly className="is-rounded">{endereco.uf}</Input.ReadOnly>
                )}
              </Input.Root>
            </Column.Content>
          </Column.Root>

          <Input.Root className="mb-3">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Nome do Responsavel
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={responsavel}
                setValue={setResponsavel}
                field={'nome'}
                required={true}
                disabled={disabledList?.descricao}
                maskSetValue={'lower'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>

          <Column.Root>
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  CPF do Responsável
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    className="is-rounded"
                    value={responsavel}
                    setValue={setResponsavel}
                    field={'cpf'}
                    required={true}
                    disabled={disabledList?.descricao}
                    maskSetValue={'cpf'}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content>
              <Input.Root className="mb-3">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  Telefone do Responsável
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    className="is-rounded"
                    value={responsavel}
                    setValue={setResponsavel}
                    field={'telefone'}
                    required={true}
                    disabled={disabledList?.descricao}
                    maskSetValue={'tel'}
                  ></Input.Prompt>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
            <Column.Content size="">
              <Input.Root className="mb-3">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  E-mail do Responsável{' '}
                </Input.Label>
                <Input.Contents>
                  <Input.Prompt
                    type="email"
                    className="is-rounded"
                    value={responsavel}
                    setValue={setResponsavel}
                    field={'email'}
                    required={true}
                    disabled={disabledList?.descricao}
                  ></Input.Prompt>
                  <p className="has-text-danger ml-3">{statusUserAdmin}</p>
                </Input.Contents>
              </Input.Root>
            </Column.Content>
          </Column.Root>

          <Column.Root className="is-mobile mr-6 pr-2">
            <Column.Content>
              <Input.Root className="mb-5">
                <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                  Planos
                </Input.Label>
                <Select.Root>
                  <Select.Content
                    color="is-grey"
                    className="is-fullwidth is-rounded"
                    value={planos}
                    setValue={setPlanos}
                    disabled={disabledList?.type}
                  >
                    <Select.Option value={null} title={''} />
                    <Select.Search value={search} setValue={setSearch} />
                    {Object.keys(listplanos).map((key, index) => {
                      return <Select.Option key={index} value={key} title={listplanos[key].nome} />
                    })}
                  </Select.Content>
                </Select.Root>
              </Input.Root>
            </Column.Content>
            <Column.Content size="is-1 mr-5">
              <Button.Bt
                className="mt-5 is-rounded"
                color="is-dark"
                colorText=""
                action={() => {
                  AddPlanInList(planos)
                }}
                loading={loading}
              >
                Adicionar
              </Button.Bt>
            </Column.Content>
          </Column.Root>

          <div className="box" style={{ marginTop: -30 }}>
            <div className="table-container">
              <Table.Root className="is-fullwidth has-text-centered mb-5 mt-2">
                <Table.Head>
                  <Table.Row>
                    <Table.Cell>Nº</Table.Cell>
                    <Table.Cell>Nome</Table.Cell>
                    <Table.Cell>Valor</Table.Cell>
                    <Table.Cell>Data Inicio</Table.Cell>
                    <Table.Cell>Data Término</Table.Cell>
                    <Table.Cell>Ações</Table.Cell>
                  </Table.Row>
                </Table.Head>
                <Table.Body>
                  {Object.keys(assinaturas).map((key, index) => {
                    return (
                      <Table.Row key={index}>
                        <Table.Cell className="is-vcentered" size={10}>
                          {index}
                        </Table.Cell>
                        <Table.Cell className="is-vcentered">
                          <span title={key}>{assinaturas[key].nome}</span>
                        </Table.Cell>
                        <Table.Cell className="is-vcentered">R$ {assinaturas[key].value}</Table.Cell>
                        <Table.Cell className="is-vcentered" size={200}>
                          <Input.Prompt
                            type="date"
                            className="is-rounded"
                            value={assinaturas[key].dtStart}
                            setValue={(value) => {
                              return setAssinaturas((prev) => ({
                                ...prev,
                                [key]: {
                                  ...prev[key],
                                  dtStart: value,
                                  dtEnd: SomarDiasAData(prev[key].type_expired, value),
                                },
                              }))
                            }}
                            required={true}
                            disabled={disabledList?.dtStart}
                          ></Input.Prompt>
                        </Table.Cell>
                        <Table.Cell className="is-vcentered" size={200}>
                          {DataComTraçoParaDataComBarra(assinaturas[key]?.dtEnd)} <br />{' '}
                          <p className="is-size-7">({assinaturas[key]?.type_expired} Dias)</p>
                        </Table.Cell>
                        <Table.Cell className="is-vcentered">
                          <Button.Bt
                            className=""
                            color="is-danger"
                            size={'is-small'}
                            colorText="has-text-white"
                            action={() => {
                              DeletePlansOfList(key)
                            }}
                            loading={loading}
                          >
                            <Icon size={15}>trash</Icon>
                          </Button.Bt>
                        </Table.Cell>
                      </Table.Row>
                    )
                  })}
                </Table.Body>
              </Table.Root>
            </div>
          </div>
        </Column.Content>
      </Column.Root>
      <Column.Root className="mt-5 mb-6">
        <Column.Content>
          <Button.Root align="right">
            <Button.Content>
              <Button.A color="is-light" link={Path.AClients} loading={loading}>
                {type === 'edit' ? 'Voltar' : 'Cancelar'}
              </Button.A>
            </Button.Content>
            <Button.Content>
              <Button.Bt className="" color="is-success" colorText="has-text-white" type={'submit'} loading={loading}>
                {type === 'edit' ? 'Editar' : 'Salvar'}
              </Button.Bt>
            </Button.Content>
          </Button.Root>
        </Column.Content>
      </Column.Root>
    </div>
  )
}
