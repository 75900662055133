import React, { useEffect, useState } from 'react'

export const SelectContent = ({
  children,
  id = '',
  color = 'is-light',
  size = null,
  style = {},
  className = '',
  value,
  setValue,
  field = null,
  disabled = null,
  required = false,
  multiples = null,
  maxOptionsMultiple = null,
}) => {
  const changeValue = (event) => {
    if (field) {
      setValue({ ...value, [field]: event.target.value })
    } else {
      setValue(event.target.value)
    }
  }

  const [colorText, setColorText] = useState('has-text-grey')

  useEffect(() => {
    if (value[field]) {
      setColorText('hsl(0, 0%, 21%)')
    } else {
      setColorText('hsl(0, 0%, 48%)')
    }
  }, [value])

  return (
    <div className={`select ${color} ${size} ${className} ${multiples && 'is-multiple'}`} style={style}>
      <select
        id={id}
        value={field ? value[field] : value}
        onChange={changeValue}
        disabled={disabled}
        required={required}
        style={{ color: colorText }}
        multiple={multiples}
        size={maxOptionsMultiple}
      >
        {children}
      </select>
    </div>
  )
}
