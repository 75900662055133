import { Button } from '../../../../components/ui/button'
import { Modal } from '../../../../components/ui/modal'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import { File } from '../../../../components/ui/file'
import { Icon } from '../../../../components/icons'
import payment from '../../../../assets/paymentsInport1.png'

export const ModalUploadPayment = ({
  active = true,
  loading,
  setActive,
  formContext,
  setFormContext,
  actions = {
    submit: () => {},
    download: () => {},
  },
}) => {
  if (active) {
    return (
      <Form.Root
        action={() => {
          actions.submit()
        }}
      >
        <Modal.Root active={active}>
          <Modal.Contents witdh={720}>
            <Modal.Header title={'Importar Pagamentos'}>
              <Modal.HeaderClose setActive={setActive}></Modal.HeaderClose>
            </Modal.Header>
            <Modal.Body>
              {loading ? (
                <>
                  <Icon>loading</Icon>
                  <p className="has-text-centered">Importando planilha...</p>
                </>
              ) : (
                <>
                  <p className="has-text-grey mx-3 has-text-centered">
                    {`Ao finalizar o evento, uma planilha de pagamentos foi automaticamente gerada e baixada para o seu
                    dispositivo.Caso não tenha mais o arquivo ou precise de uma nova cópia, você pode baixá-lo
                    novamente clicando no botão "Gerar Novos Pagamentos"`}
                  </p>

                  <Column.Root>
                    <Column.Content>
                      <Button.Root align="centered">
                        <Button.Content>
                          <Button.Bt
                            className="mt-2"
                            color="is-light"
                            loading={false}
                            action={() => {
                              actions.download()
                            }}
                          >
                            <p>Gerar Nova Planilha de Pagamentos</p>
                          </Button.Bt>
                        </Button.Content>
                      </Button.Root>
                    </Column.Content>
                  </Column.Root>

                  <p className="has-text-grey mx-3 has-text-centered">
                    {`Abra a planilha e acesse a aba PAGAMENTOS, preenchendo o status de cada pagamento com "pago" ou "pendente" conforme necessário.`}
                    <br />
                    <img src={payment} alt="pago/pendente" className="mt-2" />
                  </p>

                  <p className="has-text-grey mx-3 has-text-centered">
                    {`Após atualizar os dados, importe a planilha no botão abaixo para registrar os pagamentos da staff.`}
                  </p>
                  <Column.Root>
                    <Column.Content>
                      <File.Root color="is-success">
                        <File.Input
                          setFile={setFormContext}
                          field={'file_payment'}
                          accepts=".xls, .xlsx, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                        ></File.Input>
                        <File.Contents>
                          <File.Icon>
                            <Icon className={`${formContext?.file_payment ? 'has-text-white' : 'has-text-dark'}`}>
                              fileExport
                            </Icon>
                          </File.Icon>
                          <p
                            className={formContext?.file_payment ? 'has-text-white' : 'has-text-dark'}
                            title={
                              formContext?.file_payment ? formContext?.file_payment.name : 'Nenhum arquivo carregado'
                            }
                          >
                            {formContext?.file_payment
                              ? String(formContext?.file_payment.name).slice(0, 10) +
                                ' ... ' +
                                String(formContext?.file_payment.name).slice(-10)
                              : 'Importar'}
                          </p>
                        </File.Contents>
                      </File.Root>
                    </Column.Content>
                  </Column.Root>
                </>
              )}
            </Modal.Body>
            <Modal.Footer>
              <Button.Root>
                <Button.Content>
                  <Button.Bt
                    color="is-success"
                    colorText={'has-text-white'}
                    type="submit"
                    disabled={!formContext?.file_payment}
                    loading={loading}
                  >
                    Processar Pagamentos
                  </Button.Bt>
                </Button.Content>
              </Button.Root>
            </Modal.Footer>
          </Modal.Contents>
        </Modal.Root>
      </Form.Root>
    )
  } else {
    return <></>
  }
}
