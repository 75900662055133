import { Button } from '../../../../components/ui/button'
import { Modal } from '../../../../components/ui/modal'
import { Select } from '../../../../components/ui/select'
import { Input } from '../../../../components/ui/input'
import { Column } from '../../../../components/layouts/columns'
// import { Table } from '../../../../components/ui/table'
// import { Image } from '../../../../components/ui/image'
import { DataComTraçoParaDataComBarra } from '../../../../utils/dates'
import { Icon } from '../../../../components/icons'
import { Table } from '../../../../components/ui/table'
import { Form } from '../../../../components/ui/form'
export const ModalAddCandidates = ({
  active = true,
  setActive,
  formContext,
  dataContext,
  setFormContext,
  infosContext,
  setSearch,
  search,
  actions = {
    searchFavorite: () => {},
    add: () => {},
  },
  loading,
}) => {
  if (active) {
    return (
      <Modal.Root active={active}>
        <Modal.Contents witdh={720}>
          <Modal.Header title={'Adicionar Colaborador ao Evento'}>
            <Modal.HeaderClose setActive={setActive}></Modal.HeaderClose>
          </Modal.Header>

          {(infosContext?.filters?.dates || []).length > 0 ? (
            <>
              <Modal.Body>
                <Column.Root>
                  <Column.Content>
                    <Input.Root>
                      <Input.Label
                        className="ml-3 has-text-grey-light is-size-7"
                        style={{ marginBottom: 0 }}
                        required={true}
                      >
                        Vaga
                      </Input.Label>
                      <Select.Root>
                        <Select.Content
                          color="is-grey"
                          className="is-fullwidth is-rounded is-small"
                          value={formContext}
                          setValue={setFormContext}
                          field={'jobInvite'}
                          required={true}
                        >
                          <Select.Option value={null} title={''} />
                          {/* <Select.Option value={'todos'} title={'Todos'} /> */}
                          {infosContext?.ListJobOffers.map((job) => {
                            console.log(infosContext)
                            return (
                              <Select.Option
                                key={`job-${job.id}`}
                                value={job.id}
                                title={`${DataComTraçoParaDataComBarra(job.date)} - ${job.positionName}`}
                              />
                            )
                          })}
                        </Select.Content>
                      </Select.Root>
                    </Input.Root>
                  </Column.Content>
                </Column.Root>

                <Column.Root>
                  <Column.Content>
                    <Input.Label
                      className="ml-3 has-text-grey-light is-size-7"
                      style={{ marginBottom: 0 }}
                      required={true}
                    >
                      Lista de Favoritos
                    </Input.Label>
                    <Form.Root
                      action={() => {
                        actions.searchFavorite()
                      }}
                    >
                      <Input.Root className="mt-2">
                        <Input.Contents alignIcon={'left'}>
                          <Input.Prompt value={search} setValue={setSearch} placeholder="Pesquisar..."></Input.Prompt>
                          <Input.Icon align="is-left">
                            <Icon size={20}>search</Icon>
                          </Input.Icon>
                        </Input.Contents>
                      </Input.Root>
                    </Form.Root>
                    {!loading ? (
                      dataContext?.favorites?.data.length > 0 && (
                        <>
                          <Table.Root className="is-fullwidth">
                            <Table.Head>
                              <Table.Row>
                                <Table.Cell></Table.Cell>
                                <Table.Cell className="has-text-centered">Nome</Table.Cell>
                                <Table.Cell className="has-text-centered">CPF</Table.Cell>
                                <Table.Cell className="has-text-centered">Ação</Table.Cell>
                              </Table.Row>
                            </Table.Head>
                            <Table.Body>
                              {dataContext?.favorites?.data.map((colab, index) => {
                                return (
                                  <Table.Row key={`colabquery${index}`}>
                                    <Table.Cell className="is-vcentered">
                                      <figure className="image is-48x48 mr-3 mt-1">
                                        <img className="is-rounded" src={colab.photoUrl} alt="photoUrl..." />
                                      </figure>
                                    </Table.Cell>
                                    <Table.Cell className="is-vcentered">{colab.name}</Table.Cell>
                                    <Table.Cell className="is-vcentered">{colab.cpf}</Table.Cell>
                                    <Table.Cell className="is-vcentered">
                                      <Button.Bt
                                        color="is-success"
                                        size={'is-small'}
                                        colorText={'has-text-white'}
                                        loading={loading}
                                        action={() => {
                                          actions.add({ ...colab, jobInvite: formContext?.jobInvite })
                                        }}
                                      >
                                        +
                                      </Button.Bt>
                                    </Table.Cell>
                                  </Table.Row>
                                )
                              })}
                            </Table.Body>
                          </Table.Root>
                        </>
                      )
                    ) : (
                      <div className="mt-5">
                        <Icon className="mt-5">loading</Icon>
                      </div>
                    )}
                  </Column.Content>
                </Column.Root>
              </Modal.Body>
              <Modal.Footer></Modal.Footer>
            </>
          ) : (
            <Modal.Body>
              <article className="message is-danger">
                <div className="message-body">
                  Este eventos não possui <strong>vagas criadas</strong>, para continuar, crie algumas vagas
                </div>
              </article>
            </Modal.Body>
          )}
        </Modal.Contents>
      </Modal.Root>
    )
  } else {
    return <></>
  }
}
