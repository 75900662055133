import { useEffect, useMemo, useState } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import { TryCatch } from '../../../../utils/general'
import { getDocuments } from '../../../../firebase/firestore'
import { GetPaginationFavoriteCollaborators } from '../../../../firebase/functions'

export const useClientFormTeams = () => {
  const {
    user,
    setAlerts,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    setLoading,
    loading,
    setFormContext,
    formContext,
  } = useGlobalContext()

  const [search, setSearch] = useState('')
  const [searchListTeam, setSearchListTeam] = useState('')
  const clientId = user?.client?.id || null

  const getInfos = async () => {
    // coleta as permissões globais em CONFIG/PERMISSIONS
    const getCargos = await getDocuments('cargos')
    const positionById = getCargos.reduce((acc, value) => {
      const id = String(value.id)
      const name = value?.name || value?.nome
      acc[id] = name
      return acc
    }, {})
    console.log(positionById)
    setInfosContext({ positionById })
  }

  const getDataQuery = async (query = null) => {
    let newQuery
    if (query) {
      newQuery = { ...query }
    } else {
      newQuery = { search: String(search).toLowerCase().trim() }
    }

    const getcolaborators = await GetPaginationFavoriteCollaborators(1, 100, clientId, newQuery, 'dt_created')
    // console.log(getcolaborators)
    setDataContext(getcolaborators)
  }

  const addCollaboratorInTeams = async (colab) => {
    // console.log(colab)
    await setFormContext((prev) => {
      const newTeams = (prev?.teams || []).filter((value) => value.uuid !== colab.uuid)
      const index = newTeams.length
      const newColab = { index, uuid: colab.uuid, photoUrl: colab?.photoUrl, cpf: colab.cpf, name: colab.name }
      return { ...prev, teams: [newColab, ...newTeams] }
    })

    setSearchListTeam('')
    // return setAlerts((prev) => [
    //   ...prev,
    //   {
    //     type: 'success',
    //     title: `Colaborador Adicionado'}`,
    //     msg: `O Colaborador foi adicionado a equipe com sucesso...`,
    //   },
    // ])
  }

  const deleteCollaboratorInTeams = async (colab) => {
    await setFormContext((prev) => {
      const newTeams = (prev?.teams || []).filter((value) => value.uuid !== colab.uuid)
      return { ...prev, teams: [...newTeams] }
    })

    // return setAlerts((prev) => [
    //   ...prev,
    //   {
    //     type: 'success',
    //     title: `Colaborador Deletado`,
    //     msg: `O colaborador foi removido da equipe com sucesso...`,
    //   },
    // ])
  }

  const FetchGetInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  const FetchGetDataQuery = async (query = null) => {
    await TryCatch(
      () => {
        getDataQuery(query)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleAddCollaboratorInTeams = async (colab) => {
    await TryCatch(
      () => {
        addCollaboratorInTeams(colab)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleDeleteCollaboratorInTeams = async (colab) => {
    await TryCatch(
      () => {
        deleteCollaboratorInTeams(colab)
      },
      setLoading,
      setAlerts,
    )
  }

  const teamsFiltered = useMemo(() => {
    if (searchListTeam !== '') {
      return (formContext?.teams || []).filter((value) => {
        const searchString = `${value.name}|${value.cpf}|${value.uuid}`.toLowerCase()
        return searchString.includes(searchListTeam.toLowerCase())
      })
    }
    return formContext?.teams || []
  }, [formContext?.teams, searchListTeam])

  useEffect(() => {
    console.log(teamsFiltered)
  }, [teamsFiltered])

  useEffect(() => {
    console.log(searchListTeam)
  }, [searchListTeam])

  // executa quando carrega a pagina e quando é auterado o getUserList
  useEffect(() => {
    setDataContext({})
    FetchGetInfos()
    FetchGetDataQuery({ page: 1 })
  }, [])

  useEffect(() => {
    console.log(formContext)
  }, [formContext])

  return {
    loading,
    search,
    setSearch,
    searchListTeam,
    setSearchListTeam,
    teamsFiltered,
    dataContext,
    infosContext,
    FetchGetDataQuery,
    FetchGetInfos,
    HandleAddCollaboratorInTeams,
    HandleDeleteCollaboratorInTeams,
  }
}
