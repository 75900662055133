import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
import { Button } from '../../../components/ui/button'
import { Icon } from '../../../components/icons'
import { ClientsFormEventsFinally } from './_form/clientsFormEventsFinally'
import { useClientEventsFinally } from './_hooks/useClientEventsFinally'
import { ModalSelectCandidacy } from './_modal/modalSelectCandidacy'
import { Form } from '../../../components/ui/form'

export const ClientEventsFinally = () => {
  const {
    loading,
    statusLoading,
    filter,
    setFilter,
    setFormContext,
    formContext,
    dataContext,
    infosContext,
    FetchGetDatas,
    FetchGetInfos,
    modalSelectCandidacy,
    setModalSelectCandidacy,
    FetchGetDatasQuery,
    SelectCandidacy,
    DuplicateCandidacy,
    DeleteCandidacy,
    HandleFinallyEvents,
    HandleSaveFinallyInfoEvent,
  } = useClientEventsFinally()

  return (
    <>
      <ModalSelectCandidacy
        loading={loading}
        active={modalSelectCandidacy}
        setActive={() => {
          setModalSelectCandidacy(false)
          setFormContext((prev) => ({ ...prev, typeSelectCandidacy: '' }))
        }}
        values={{}}
        filter={filter}
        setFilter={setFilter}
        FormAction={FetchGetDatasQuery}
        dataContext={dataContext}
        infosContext={infosContext}
        actions={{
          select: (values) => {
            SelectCandidacy(values)
          },
        }}
      />
      {loading === false && statusLoading === '' ? (
        <div>
          <Form.Root
            action={() => {
              HandleFinallyEvents()
            }}
          >
            <Page.Section className="is-fullheight-with-navbar mt-6 mx-3" style={{ minHeight: '20vh' }}>
              <Column.Root>
                {/* Titulo da pagina */}
                <Column.Content>
                  <p className="subtitle is-size-4">
                    <Icon size={30} className={'mr-3'}>
                      checkin
                    </Icon>
                    Finalizar Evento
                  </p>
                </Column.Content>

                {/* Botões superiores */}
                <Column.Content className="has-text-right">
                  <Button.Root align="right">
                    <Button.Content>
                      <Button.Bt
                        color="is-dark"
                        colorText="has-text-white"
                        action={async () => {
                          FetchGetInfos()
                          FetchGetDatas()
                        }}
                        loading={loading}
                      >
                        <Icon size={20} margin={-1}>
                          sync
                        </Icon>
                      </Button.Bt>
                    </Button.Content>
                    <Button.Content>
                      <Button.Bt
                        className=""
                        color="is-light"
                        colorText="has-text-success"
                        loading={loading}
                        title={'Exportar Planilha de Pagamentos'}
                        action={() => {}}
                      >
                        <Icon size={25} margin={-5}>
                          fileExport
                        </Icon>
                      </Button.Bt>
                    </Button.Content>
                  </Button.Root>
                </Column.Content>
              </Column.Root>

              <ClientsFormEventsFinally
                formContext={formContext}
                setFormContext={setFormContext}
                loading={loading}
                setSelectCandidacy={setModalSelectCandidacy}
                actions={{
                  delete: (values, field) => {
                    DeleteCandidacy(values, field)
                  },
                  duplicated: (values, field) => {
                    DuplicateCandidacy(values, field)
                  },
                }}
              ></ClientsFormEventsFinally>

              {formContext?.statusEvent === 'open' && (
                <Column.Root className="mt-5 mb-6">
                  <Column.Content>
                    <Button.Root align="right">
                      <Button.Content>
                        <Button.Bt
                          color="is-light"
                          type="button"
                          loading={loading}
                          action={() => {
                            HandleSaveFinallyInfoEvent()
                          }}
                        >
                          Salvar
                        </Button.Bt>
                      </Button.Content>
                      <Button.Content>
                        <Button.Bt
                          className=""
                          color="is-success"
                          colorText="has-text-white"
                          type={'submit'}
                          loading={loading}
                        >
                          Finalizar
                        </Button.Bt>
                      </Button.Content>
                    </Button.Root>
                  </Column.Content>
                </Column.Root>
              )}
            </Page.Section>
          </Form.Root>
        </div>
      ) : (
        <div className="mt-6 has-text-centered pt-6">
          <Icon>{'loading'}</Icon> {/* Ajuste para exibir um valor padrão caso 'loading' não seja boolean */}
          <p className="has-text-grey">{String(statusLoading) || 'Carregando...'}</p>
        </div>
      )}
    </>
  )
}
