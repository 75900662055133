export const usePaginationFuntional = (pagination, page, setPage, FetchData) => {
  const currentPage = parseInt(pagination?.currentPage) || 0 // default para 1
  const totalPages = parseInt(pagination?.totalPages) || 0 // default para 1
  //   const itemsPerPage =
  //     parseInt(paginationData?.itemsPerPage) || 0
  const totalItems = parseInt(pagination?.totalItems) || 0

  // console.log(currentPage, totalItems, totalPages, pagination)

  const nextPage = async () => {
    setPage(currentPage + 1)
    await FetchData()
  }

  const PreviousPage = async () => {
    setPage(currentPage - 1)
    await FetchData()
  }

  return {
    currentPage,
    totalPages,
    totalItems,
    nextPage,
    PreviousPage,
  }
}
