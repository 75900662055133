export const TransformationforMask = (value, mask) => {
  if (!value) {
    value = ''
  }

  switch (mask) {
    case 'text':
      return value

    case 'title':
      return value
        .toLowerCase()
        .split(' ')
        .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
        .join(' ')

    case 'upper':
      return value.toUpperCase()

    case 'lower':
      return value.toLowerCase()

    case 'cpfCnpj':
      // Remove qualquer caractere que não seja número
      value = value.replace(/\D/g, '')

      // Limita o valor a no máximo 11 caracteres para CPF e 14 para CNPJ
      if (value.length <= 11) {
        value = value.slice(0, 11) // Limita a 11 dígitos no máximo (CPF)
      } else {
        value = value.slice(0, 14) // Limita a 14 dígitos no máximo (CNPJ)
      }

      if (value.length === 11) {
        // Máscara para CPF (###.###.###-##)
        return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')
      } else if (value.length === 14) {
        // Máscara para CNPJ (##.###.###/####-##)
        return value.replace(/(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/, '$1.$2.$3/$4-$5')
      } else {
        // Retorna o valor sem formatação se não for CPF ou CNPJ válido
        return value
      }

    case 'cpf':
      // Remove qualquer caractere que não seja número
      value = value.replace(/\D/g, '').slice(0, 11)

      return value.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, '$1.$2.$3-$4')

    case 'tel':
      // Remove qualquer caractere que não seja número
      value = value.replace(/\D/g, '')

      if (value.length <= 10) {
        value = value.slice(0, 10) // Limita a 10 dígitos no máximo (CPF)
      } else {
        value = value.slice(0, 11) // Limita a 11 dígitos no máximo (CNPJ)
      }

      if (value.length === 10) {
        // Máscara para telefone fixo (## ####-####)
        return value.replace(/(\d{2})(\d{4})(\d{4})/, '($1) $2-$3')
      } else if (value.length === 11) {
        // Máscara para celular (## #####-####)
        return value.replace(/(\d{2})(\d{5})(\d{4})/, '($1) $2-$3')
      } else {
        // Retorna o valor sem formatação se não for um número de telefone ou celular válido
        return value
      }

    case 'cep': {
      // Remove qualquer caractere que não seja número
      value = value.replace(/\D/g, '').slice(0, 8)

      // Aplica a formatação #####-###
      return value.replace(/(\d{5})(\d{3})/, '$1-$2')
    }
    case 'numero': {
      // Remove qualquer caractere que não seja número
      value = value.replace(/\D/g, '')
      return value
    }
    default:
      return value
  }
}

export const TransformNumberToDecimal = (value) => {
  return Number(value).toLocaleString('pt-BR', { minimumFractionDigits: 2, maximumFractionDigits: 2 })
}
