import { Modal } from '../../../../components/ui/modal'
import { Input } from '../../../../components/ui/input'
import { Column } from '../../../../components/layouts/columns'
import { Form } from '../../../../components/ui/form'
import { Button } from '../../../../components/ui/button'
import { Table } from '../../../../components/ui/table'
import { Icon } from '../../../../components/icons'

export const ModalRegisterWorkingHours = ({
  active = true,
  setActive,
  loading,
  formContext,
  setFormContext,
  infosContext,
  actions = {
    submit: () => {},
    delete: () => {},
  },
}) => {
  if (active) {
    return (
      <Modal.Root active={active}>
        <Modal.Contents witdh={720}>
          <Modal.Header
            title={`Registrar horas ${formContext?.CandidacyRegisterWorkingHours === 'all' ? 'Gerais' : 'para o Colaborador'}`}
          >
            <Modal.HeaderClose setActive={setActive}></Modal.HeaderClose>
          </Modal.Header>
          <Modal.Body>
            <Form.Root
              action={() => {
                actions.submit()
              }}
            >
              <Column.Root className="is-mobile">
                <Column.Content>
                  <Input.Root>
                    <Input.Label
                      className="ml-3 has-text-grey-light is-size-7"
                      style={{ marginBottom: 0 }}
                      required={true}
                    >
                      Data e Hora
                    </Input.Label>
                    <Input.Prompt
                      value={formContext}
                      setValue={setFormContext}
                      field={'workingDate'}
                      type="date"
                    ></Input.Prompt>
                  </Input.Root>
                </Column.Content>
                <Column.Content>
                  <Input.Root>
                    <Input.Label
                      className="ml-3 has-text-grey-light is-size-7"
                      style={{ marginBottom: 0 }}
                      required={true}
                    >
                      Data e Hora
                    </Input.Label>
                    <Input.Prompt
                      value={formContext}
                      setValue={setFormContext}
                      field={'workingTime'}
                      type="time"
                    ></Input.Prompt>
                  </Input.Root>
                </Column.Content>
              </Column.Root>

              <Column.Root className="is-mobile">
                <Column.Content>
                  <Input.Root>
                    <Input.Label
                      className="ml-3 has-text-grey-light is-size-7"
                      style={{ marginBottom: 0 }}
                      required={true}
                    >
                      Comentário
                    </Input.Label>
                    <Input.Prompt
                      value={formContext}
                      setValue={setFormContext}
                      field={'workingComment'}
                      type="text"
                    ></Input.Prompt>
                  </Input.Root>
                </Column.Content>
                <Column.Content size="is-2">
                  <Button.Root align="right" className="mt-4">
                    <Button.Content>
                      <Button.Bt type="submit" className="is-rounded ml-2" color="is-dark" loading={loading}>
                        Adicionar
                      </Button.Bt>
                    </Button.Content>
                  </Button.Root>
                </Column.Content>
              </Column.Root>
            </Form.Root>

            {(infosContext?.hoursRegistereds || []).length > 0 && (
              <div className="box mt-5">
                <Table.Root className="is-fullwidth">
                  <Table.Head>
                    <Table.Row>
                      <Table.Cell>Data</Table.Cell>
                      <Table.Cell>Comentário</Table.Cell>
                      <Table.Cell>Criado por</Table.Cell>
                      <Table.Cell>Criado em</Table.Cell>
                      <Table.Cell>Ações</Table.Cell>
                    </Table.Row>
                  </Table.Head>
                  <Table.Body>
                    {(infosContext?.hoursRegistereds || []).map((register) => {
                      return (
                        <Table.Row key={register.id}>
                          <Table.Cell>{register.time}</Table.Cell>
                          <Table.Cell>{register.comment}</Table.Cell>
                          <Table.Cell>{register.registerBy}</Table.Cell>
                          <Table.Cell>{register.dt_created}</Table.Cell>
                          <Table.Cell>
                            <Button.Bt
                              color="is-danger"
                              action={() => {
                                actions.delete(register)
                              }}
                              loading={loading}
                            >
                              <Icon size={15}>trash</Icon>
                            </Button.Bt>
                          </Table.Cell>
                        </Table.Row>
                      )
                    })}
                  </Table.Body>
                </Table.Root>
              </div>
            )}
          </Modal.Body>
        </Modal.Contents>
      </Modal.Root>
    )
  } else {
    return <></>
  }
}
