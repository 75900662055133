import { useParams, useSearchParams } from 'react-router-dom'
import { useEffect, useState } from 'react'

import { useGlobalContext } from '../../../../context/ContextGlobal'
import {
  addSubDocument,
  deleteSubDocument,
  getDocument,
  getDocuments,
  getDocumentsWithQuery,
  getSubDocument,
  getSubDocuments,
  getSubDocumentsWithQuerys,
  incrementFieldInSubDocument,
  setSubDocument,
  updateArrayInSubDocument,
  updateSubDocument,
} from '../../../../firebase/firestore'
import { TryCatch } from '../../../../utils/general'
import { GetPaginationFavoriteCollaborators, GetPaginationStaff } from '../../../../firebase/functions'
import { FilteredQuery } from '../_utils/filteredQuery'
import { statusStaff } from '../_utils/statusCandidate'
import { DataComTraçoParaDataComBarra, DateTimeNow } from '../../../../utils/dates'
import { schemaExportAllStaff } from '../../../../utils/schemaExports'
import { ExportAllStaffPayment, ExportAllWorkingTimeRegister, ExportToExcel } from '../../../../utils/exportToExcel'
import { CreateSearchField } from '../../../../utils/createSeachField'
import { SetHistoryEvent } from '../../../../utils/requests'
import { ReadExcelFile } from '../../../../utils/readExcel'
import { TransformationforMask } from '../../../../utils/masks'

export const useClientEventsStaff = () => {
  const {
    user,
    setAlerts,
    loading,
    setLoading,
    dataContext,
    setDataContext, // contexto que guarda dados de lista para a pagina
    infosContext,
    setInfosContext, // contexto que guarda dados adicionais para a pagina funcionar
    formContext,
    setFormContext, // contexto que guarda dados de formularios da pagina
  } = useGlobalContext()

  const { id } = useParams()

  const clientId = user?.client?.id

  const [searchParams, setSearchParams] = useSearchParams()

  const [filter, setFilter] = useState({ date: 'todos', idPosition: 'todos', status: 'todos', filtered: false })

  const [checks, setChecks] = useState({})

  const [checkAll, setCheckall] = useState(false)

  const [page, setPage] = useState(1)

  const [searchFavorite, setSearchFavorite] = useState('')

  const [modalInvite, setModalInvite] = useState(false)

  const [modalAdd, setModalAdd] = useState(false)

  const [modalInfoCandidacy, setModalInfoCandidacy] = useState(false)

  const [modalUploadPayments, setModalUploadPayments] = useState(false)

  const [modalRegisterWorkingHours, setModalRegisterWorkingHours] = useState(false)

  // seta os valores na url da pagina
  const setQueryInURL = async (query, more = {}) => {
    const filteredQuery = FilteredQuery(query)
    // seta nos parametros da url um novo filtro
    await setSearchParams(await schemaUrlParams({ ...filteredQuery, ...more }))
  }

  // schema para subir na url da pagina quando pesquisar
  const schemaUrlParams = async (params) => {
    const filteredParams = {
      page: params?.page === 'null' ? null : params?.page,
      date: params?.date === 'null' ? null : params?.date,
      status: params?.status === 'null' ? null : params?.status,
      idPosition: params?.idPosition === 'null' ? null : params?.idPosition,
      idJobOffers: Array.isArray(params?.idJobOffers) && params.idJobOffers.length > 0 ? params.idJobOffers : undefined,
      filtered: params?.filtered === 'null' ? null : params?.filtered,
    }

    // Remove propriedades com valor undefined
    return FilteredQuery(filteredParams)
  }

  // atualiza os filtro com base nos parametros da pagina
  const updateFiltersByUrlParams = async (query, pageQuery) => {
    let newQuery = null
    let newFilter
    let page = 1
    if (pageQuery !== null) {
      page = pageQuery
    }

    if (query) {
      newQuery = { ...query, page }
      newFilter = { ...newQuery }
    } else {
      // caso não tenha query, ele busca na url e salva no filtro caso tenha algo
      const idJobOffers = searchParams.getAll('idJobOffers')
      const status = searchParams.get('status') != null ? Number(searchParams.get('status')) : null
      const search = searchParams.get('search')
      const date = searchParams.get('date')
      const idPosition = searchParams.get('idPosition')
      // Crie um novo objeto de consulta sem redefinir a variável 'query'
      newFilter = { idJobOffers, status, search, page, idPosition, date }
      newQuery = FilteredQuery(newFilter)
    }

    newFilter = {
      ...newFilter,
      filtered: newQuery?.status !== null && !isNaN(newQuery?.status), // caso true, aparece os checkBox na frente do colaborador
    }
    console.log(newQuery?.status !== null)
    console.log(!isNaN(newQuery?.status))
    console.log(newFilter)
    setFilter(newFilter)
    setChecks({})
    await setQueryInURL(newQuery)
    return { newQuery }
  }

  // coleta os dados da staff do evento
  const getDatas = async ({ query = null, pageQuery = null, limit = 500 } = {}) => {
    await setDataContext({})

    // atualiza os paremtros com a query nova, ou busca da url da pagina
    const { newQuery } = await updateFiltersByUrlParams(query, pageQuery)
    const newPage = newQuery?.page || 1

    const getStaff = await GetPaginationStaff(newPage, limit, id, newQuery, { field: 'status', direction: 'asc' })
    const newData = {
      staff: getStaff,
    }

    setDataContext((prev) => ({ ...prev, ...newData }))
    return newData
  }

  const getDataFavorites = async (query = null) => {
    let newQuery
    if (query) {
      newQuery = { ...query }
    } else {
      newQuery = { search: String(searchFavorite).toLowerCase().trim() }
    }

    const getcolaborators = await GetPaginationFavoriteCollaborators(1, 50, clientId, newQuery, 'dt_created')
    console.log(getcolaborators)
    // console.log(getcolaborators)
    setDataContext((prev) => ({ ...prev, favorites: getcolaborators }))
  }

  const getInfos = async () => {
    const getJobOffers = await getSubDocuments('events', id, 'jobOffers')
    const getPositions = await getDocuments('cargos')
    const positionsById = getPositions.reduce((acc, curr) => {
      acc[curr.id] = curr.nome
      return acc
    }, {})
    const JobOffersById = getJobOffers.reduce((acc, curr) => {
      acc[curr.id] = { ...curr, positionName: positionsById[curr.idPosition] }
      return acc
    }, {})

    const dates = []
    const positions = []

    const getTeams = await getSubDocuments('clientes', clientId, 'teams')

    Object.values(JobOffersById).forEach((offer) => {
      if (!dates.includes(offer.date)) {
        dates.push(offer.date)
      }
      if (!positions.includes(offer.idPosition)) {
        positions.push(offer.idPosition)
      }
    })

    const filters = {
      dates,
      positions,
    }

    const newInfos = {
      positions: getPositions,
      positionsById,
      jobOffers: JobOffersById,
      ListJobOffers: Object.values(JobOffersById),
      filters,
      teams: getTeams,
    }

    console.log(newInfos)

    setInfosContext((prev) => ({ ...prev, ...newInfos }))
  }

  // coleta os dados da staff com evento usando uma pesquisa
  const queryData = async () => {
    const date = filter?.date === 'todos' ? null : filter?.date
    const position = filter?.idPosition === 'todos' ? null : filter?.idPosition
    const status = filter?.status === 'todos' ? null : Number(filter?.status)

    const datesByQuery = new Set() // Usando Set para evitar duplicatas
    const positionsByQuery = new Set() // Usando Set para evitar duplicatas

    // coleta os idJoboffers que contem as datas e cagas do filtro
    Object.values(infosContext?.jobOffers).forEach((jobOffer) => {
      if (date && jobOffer.date === date) {
        datesByQuery.add(jobOffer.id)
      }
      if (position && jobOffer.idPosition === position) {
        positionsByQuery.add(jobOffer.id)
      }
    })

    let idJobOffers = []

    // Verifica se ambos os conjuntos estão vazios
    if (datesByQuery.size === 0 && positionsByQuery.size === 0) {
      idJobOffers = [] // Retorna um array vazio
    } else if (datesByQuery.size === 0) {
      idJobOffers = Array.from(positionsByQuery) // Retorna positionsByQuery se datesByQuery estiver vazio
    } else if (positionsByQuery.size === 0) {
      idJobOffers = Array.from(datesByQuery) // Retorna datesByQuery se positionsByQuery estiver vazio
    } else {
      // Se ambos os conjuntos não estão vazios, filtra os elementos iguais
      idJobOffers = Array.from(datesByQuery).filter((value) => positionsByQuery.has(value))
    }

    const query = {
      ...filter,
      status,
      idJobOffers,
    }

    // se não achar id job compativeis e tiver o filtro ele não continua
    if (query.idJobOffers.length === 0 && date && position) {
      return setAlerts((prev) => [
        ...prev,
        [
          ...prev,
          {
            type: 'error',
            title: 'Vaga não encontrada',
            msg: `Não existe a vaga de ${infosContext?.positionsById[position]} para o dia ${date}, selecione um filtro valido, ou crie uma nova vaga.`,
          },
        ],
      ])
    }

    const moreQuery = { date, idPosition: position, page: 1 }
    delete query.filtered
    const filteredQuery = FilteredQuery({ ...query, ...moreQuery })

    console.log(filteredQuery)
    // atualiza os dados com a nova query
    setPage(1)
    await getDatas({ query: filteredQuery, pageQuery: 1 })
  }

  // realiza a limpeza da pagina
  const clearQueryData = async () => {
    await getDatas({ pageQuery: 1 })
    const clear = { date: 'todos', idPosition: 'todos', status: 'todos', search: '' }
    setSearchParams({ page: 1 })
    await setFilter(clear)
  }

  const updateValuesInfoCandidacy = async (value) => {
    const favorite = await checkFavoriteColaborator(value?.uuid)
    const blocked = await checkBlockColaborator(value?.uuid)
    const newValuesInfoCandidacy = {
      ...value,
      positionName: infosContext?.jobOffers?.[value.idJobOffers]?.positionName || '',
      date: infosContext?.jobOffers?.[value.idJobOffers]?.date
        ? DataComTraçoParaDataComBarra(infosContext.jobOffers[value.idJobOffers].date)
        : '',
      status: statusStaff[Number(value.status)] || '',
      valueCache: infosContext?.jobOffers?.[value.idJobOffers]?.value,
      favorite,
      blocked,
    }
    console.log(newValuesInfoCandidacy)
    SetValuesInfoCandidacy(newValuesInfoCandidacy)
  }

  const checkFavoriteColaborator = async (uuidColab) => {
    const idClient = user?.client?.id
    console.log(idClient)
    console.log(uuidColab)
    // Verifica se o idClient está presente
    if (!idClient) {
      setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'ID do Cliente Inexistente',
          msg: 'Por favor, faça login novamente para obtermos o ID do cliente logado.',
        },
      ])
      return false
    }

    try {
      // Tenta obter o subdocumento de favoritos
      const favorite = await getSubDocument('clientes', idClient, 'favorites', uuidColab)
      console.log(favorite)
      console.log(!!favorite)
      return !!favorite // Retorna true se houver um documento favorito, caso contrário false
    } catch (error) {
      console.log('retornou False')
      return false
    }
  }

  const checkBlockColaborator = async (uuidColab) => {
    const idClient = user?.client?.id
    console.log(idClient)
    console.log(uuidColab)
    // Verifica se o idClient está presente
    if (!idClient) {
      setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'ID do Cliente Inexistente',
          msg: 'Por favor, faça login novamente para obtermos o ID do cliente logado.',
        },
      ])
      return false
    }

    try {
      // Tenta obter o subdocumento de favoritos
      const favorite = await getSubDocument('colaboradores', uuidColab, 'blockedIn', idClient)
      console.log(favorite)
      console.log(!!favorite)
      return !!favorite // Retorna true se houver um documento favorito, caso contrário false
    } catch (error) {
      console.log('retornou False')
      return false
    }
  }

  const favoriteColaborator = async (uuidColab) => {
    const idClient = user?.client?.id
    if (!idClient) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'ID do Cliente Inexistente',
          msg: 'Por favor, faça login novamente para obtermos o ID do cliente logado.',
        },
      ])
    }
    const existFavorite = await checkFavoriteColaborator(uuidColab)
    if (!existFavorite) {
      let getColab
      try {
        getColab = await getDocument('colaboradores', uuidColab)
      } catch (error) {
        throw new Error('Colaborador não encontrado, ou excluido da base de dados...')
      }

      if (getColab) {
        const newFavorite = {
          dt_created: DateTimeNow(),
          search: CreateSearchField(getColab),
        }
        console.log(getColab)
        console.log(newFavorite)
        await setSubDocument('clientes', idClient, 'favorites', uuidColab, newFavorite)
        setFormContext((prev) => ({ ...prev, favorite: true }))
      } else {
        throw new Error('Colaborador não encontrado, ou excluido da base de dados...')
      }
    } else {
      await deleteSubDocument('clientes', idClient, 'favorites', uuidColab)
      setFormContext((prev) => ({ ...prev, favorite: false }))
    }

    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        msg: `Colaborador ${existFavorite ? 'removido dos' : 'adicionado aos'}  favoritos com sucesso`,
      },
    ])
  }

  const blockUnBlockColaborator = async (uuidColab) => {
    const idClient = user?.client?.id
    if (!idClient) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'ID do Cliente Inexistente',
          msg: 'Por favor, faça login novamente para obtermos o ID do cliente logado.',
        },
      ])
    }
    const existFavorite = await checkBlockColaborator(uuidColab)
    if (!existFavorite) {
      await setSubDocument('colaboradores', uuidColab, 'blockedIn', idClient, { dt_created: DateTimeNow() })
      setFormContext((prev) => ({ ...prev, blocked: true }))
    } else {
      await deleteSubDocument('colaboradores', uuidColab, 'blockedIn', idClient)
      setFormContext((prev) => ({ ...prev, blocked: false }))
    }

    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: `Candidatura ${!existFavorite ? 'Bloqueada' : 'Desbloqueada'}`,
        msg: !existFavorite
          ? 'o colaborador não pode mais se candidatar em seus eventos'
          : 'o colaborador pode voltar a se candidatar em seus eventos',
      },
    ])
  }

  const exportAllData = async () => {
    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: `Exportando Planilha`,
        msg: 'Estamos coletando as informações necessárias para gerar sua planilha. Esse processo pode levar alguns minutos...',
      },
    ])
    const newLimit = dataContext?.staff?.pagination?.totalItems || 5000
    const data = await getDatas({ limit: newLimit })
    const jobOffers = infosContext?.jobOffers || null
    const dataEnriched = data.staff.data.map((values) => {
      const idJobOffer = values?.idJobOffers
      const status = values?.status
      return {
        ...values,
        jobOffersName: jobOffers[idJobOffer].positionName,
        date: jobOffers[idJobOffer].date,
        statusPlus: statusStaff[status] || { status: 'Sem status' },
      }
    })

    const response = schemaExportAllStaff(dataEnriched)

    ExportToExcel(response, `Staff_${id}.xlsx`)

    await SetHistoryEvent(id, user.user.nome, `Planilha de Staff Esportada`)
    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: `Planilha Conclúida`,
        msg: 'O Processo de construir sua planilha terminou com exito...',
      },
    ])
  }

  const criar1000Candidaturas = async () => {
    try {
      const jobs = await getSubDocuments('events', id, 'jobOffers')
      const idJobs = jobs.map((job) => job.id)

      const colabs = await getDocuments('colaboradores')
      const idColabs = colabs.map((colab) => colab.id)

      // const quant = window.prompt('Quantos colaboradores vc deseja incluir? ', 5)
      const quant = prompt('Quantos colaboradores vc deseja incluir? ', 5)

      const promisses = Array.from({ length: quant }).map(async () => {
        const status = 3
        const idJobOffers = idJobs[Math.floor(Math.random() * idJobs.length)]
        const uuid = idColabs[Math.floor(Math.random() * idColabs.length)]
        const newCandidatura = {
          status,
          idJobOffers,
          uuid,
          dt_created: DateTimeNow(),
        }
        await addSubDocument('events', id, 'staff', newCandidatura)
      })

      // Espera todas as promessas serem resolvidas
      await Promise.all(promisses)

      alert('Colaboradores cadastrados')

      FetchGetdatas()
    } catch (error) {
      console.error('Erro ao criar candidaturas:', error)
      alert('Ocorreu um erro ao cadastrar colaboradores.')
    }
  }

  const createSchemaInviteTeams = async (values) => {
    const jobId = values?.jobInvite || false
    const teamId = values?.teamId || false
    // console.log(date, objTeams)
    console.log(values)

    if (!jobId || !teamId) {
      return { error: 'Selecione um "vaga" e uma "equipe" para enviar o convite' }
    }

    const jobOffer = infosContext?.jobOffers?.[jobId] || false

    if (!jobOffer) {
      return { error: 'Vaga selecionada não existe, ou foi excluida da sua base de dados' }
    }

    const getTeam = await getSubDocument('clientes', clientId, 'teams', teamId)

    const teamList = getTeam?.teams || []

    console.log(teamList)

    if (teamList.length === 0) {
      return { error: 'Equipe selecionada não possui colaboradores, revie-a antes de enviar o convite' }
    }

    const newCandidacies = teamList.flatMap((colab) => {
      console.log(jobId, jobOffer)
      return {
        uuid: colab.uuid,
        idJobOffers: jobId,
        status: 2,
        search: CreateSearchField(colab),
        dt_created: DateTimeNow(),
      }
    })

    return { data: newCandidacies }
  }

  const inviteTeams = async (values) => {
    try {
      const candidacies = await createSchemaInviteTeams(values)
      console.log(candidacies)

      if (candidacies?.error) {
        return setAlerts((prev) => [
          ...prev,
          {
            type: 'error',
            title: 'Erro ao criar convite',
            msg: candidacies?.error,
          },
        ])
      }

      // Renomeando para 'promises' e utilizando Promise.all corretamente
      const promises = candidacies.data.map(async (candidacy) => {
        let candacysExists = []
        if (candidacy?.uuid !== undefined) {
          const query = [
            { field: 'uuid', operator: '==', value: candidacy?.uuid },
            { field: 'idJobOffers', operator: '==', value: candidacy?.idJobOffers },
            { field: 'status', operator: 'in', value: [0, 2, 3, 4, 5, 6, 7, 8, 9, 10] }, // tira os status 1 e 11, pois eles podem ser criados uma nova candiatura
          ]
          candacysExists = await getSubDocumentsWithQuerys('events', id, 'staff', query, 1)
        }
        console.log(candacysExists)
        if (candacysExists.length === 0) {
          const idJobOffer = candidacy?.idJobOffers
          if (idJobOffer) {
            // id = id do evento
            try {
              console.log(candidacy)
              const idCandidacy = await addSubDocument('events', id, 'staff', candidacy)
              const DateJobOffer = infosContext?.jobOffers?.[idJobOffer].date
              const newCandidacyColab = {
                idCandidacy,
                idEvent: id,
                date: DateJobOffer,
              }
              console.log(newCandidacyColab)
              await addSubDocument('colaboradores', candidacy.uuid, 'candidacys', newCandidacyColab)
              await incrementFieldInSubDocument('events', id, 'jobOffers', idJobOffer, 'filledJob', 1)
            } catch (e) {
              setAlerts((prev) => [
                ...prev,
                {
                  type: 'error',
                  title: 'Colaborador não encontrado',
                  msg: 'Algum colaborador na sua equipe, não existe ou excluir o perfil em nossos banco de dados...',
                },
              ])
            }
          }
        }
      })

      // Espera todas as promessas serem resolvidas
      await Promise.all(promises)

      setAlerts((prev) => [
        ...prev,
        {
          type: 'success',
          title: 'Convite enviado a Equipe',
          msg: 'Foram enviados convites aos membros da equipe, aguarde a confirmação deles...',
        },
      ])
      // salvando historico
      await SetHistoryEvent(id, user.user.nome, [
        `Convite enviado a Equipe: ${infosContext?.teams.filter((teams) => teams.id === formContext?.teamId)[0].name}`,
        `Data: ${values?.dateInvite}`,
      ])
      // Resetando estados
      setFormContext((prev) => ({ ...prev, dateInvite: null, teamId: null }))
      setModalInvite(false)
      FetchGetdatas()
    } catch (error) {
      throw new Error(error)
      // Aqui você pode adicionar um alert ou mensagem de erro para o usuário
    }
  }

  const addCandidacy = async (values) => {
    const uuid = values.uuid
    const jobId = values?.jobInvite || false

    if (!jobId) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Erro ao criar convite',
          msg: 'Vaga não selecionada',
        },
      ])
    }

    const candidacy = {
      uuid,
      idJobOffers: jobId,
      status: 2,
      search: CreateSearchField(values),
      dt_created: DateTimeNow(),
    }
    const query = [
      { field: 'uuid', operator: '==', value: candidacy?.uuid },
      { field: 'idJobOffers', operator: '==', value: candidacy?.idJobOffers },
      { field: 'status', operator: 'in', value: [0, 2, 3, 4, 5, 6, 7, 8, 9, 10] }, // tira os status 1 e 11, pois eles podem ser criados uma nova candiatura
    ]

    const candacysExists = await getSubDocumentsWithQuerys('events', id, 'staff', query, 1)

    if (candacysExists.length === 0) {
      const idJobOffer = candidacy?.idJobOffers
      if (idJobOffer) {
        // id = id do evento
        try {
          const idCandidacy = await addSubDocument('events', id, 'staff', candidacy)
          const DateJobOffer = infosContext?.jobOffers?.[idJobOffer].date
          const newCandidacyColab = {
            idCandidacy,
            idEvent: id,
            date: DateJobOffer,
          }
          console.log(newCandidacyColab)
          await addSubDocument('colaboradores', candidacy.uuid, 'candidacys', newCandidacyColab)
          await incrementFieldInSubDocument('events', id, 'jobOffers', idJobOffer, 'filledJob', 1)
          setAlerts((prev) => [
            ...prev,
            {
              type: 'success',
              title: 'Convite enviado a Equipe',
              msg: 'Foram enviados convites aos membros da equipe, aguarde a confirmação deles...',
            },
          ])
          await SetHistoryEvent(id, user.user.nome, [
            `Convite enviado a Equipe: ${candidacy.name}`,
            `Data: ${values?.dateInvite}`,
          ])
          FetchGetdatas()
        } catch (e) {
          setAlerts((prev) => [
            ...prev,
            {
              type: 'error',
              title: 'Colaborador não encontrado',
              msg: 'O colaborador não existe ou excluir o perfil em nossos banco de dados...' + e,
            },
          ])
        }
      }
    } else {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Candidatura existente',
          msg: 'Esse colaborador ja foi candidatado para essa vaga',
        },
      ])
    }
  }

  const checkStatusForJustify = async (currentStatus, newStatus, nameCollaborator = null) => {
    // [statusatual from novostatus]
    const roleMotivo = [
      [0, 1],
      [0, 11],
      [11, 2],
      [2, 11],
      [1, 2],
      [3, 11],
    ]
    console.log(newStatus, currentStatus)

    const hasmotivo = roleMotivo.some(([from, to]) => from === currentStatus && to === newStatus)

    let motivo = ''
    if (hasmotivo) {
      motivo = window.prompt(
        nameCollaborator
          ? `Deseja mudar o status de ${nameCollaborator}, para "${statusStaff[Number(status)].status}"?\n\n >>> Justifique sua mudança:`
          : `Deseja mudar o status dos ${Object.keys(checks).filter((c) => checks[c]).length} colaboradores selecinados?\n\n >>> Justifique sua mudança:`,
      )

      if (motivo === '' || motivo === null) {
        setAlerts((prev) => [
          ...prev,
          {
            type: 'error',
            title: 'Jsutificativa obrigatória',
            msg: `Para realizar essa ação, é obrigatório que a justificativa`,
          },
        ])
      } else {
        return motivo
      }
    }
    return motivo
  }

  const schemaRegisterTimesPoints = ({ time = DateTimeNow(), comment = '' }) => {
    return { time, comment, registerBy: user.user.nome }
  }

  const setCheckinOrCheckout = (currentStatus, newStatus) => {
    const roleCheckin = [[3, 5]]
    const roleCheckout = [[5, 7]]
    console.log(newStatus, currentStatus)

    const hasCheckin = roleCheckin.some(([from, to]) => from === currentStatus && to === newStatus)
    const hasCheckout = roleCheckout.some(([from, to]) => from === currentStatus && to === newStatus)
    if (hasCheckin) {
      return [schemaRegisterTimesPoints({ comment: 'Check-In' })]
    } else if (hasCheckout) {
      return [schemaRegisterTimesPoints({ comment: 'Check-Out' })]
    } else {
      return []
    }
  }

  const UpdateStatusCandidacy = async (status, valuesCandidacy) => {
    // seta o status para 3
    const idCandidacy = valuesCandidacy?.id
    const nameColaborador = valuesCandidacy?.name
    const dateCandidacy = infosContext?.jobOffers[valuesCandidacy.idJobOffers]?.date
    const statusCurrent = valuesCandidacy?.status

    if (idCandidacy === null && nameColaborador === null && dateCandidacy === null && statusCurrent === null) {
      throw new Error(
        `Values Faltantes:
        idCandidacy:${idCandidacy}, 
        nameColaborador:${nameColaborador}, 
        dateCandidacy:${dateCandidacy}, 
        statusCurrent:${statusCurrent}`,
      )
    }
    const motivo = await checkStatusForJustify(statusCurrent, status, nameColaborador)
    // se motivo for vazio ele retorna
    if (motivo === null) return

    const newCadidacy = {
      status,
      dtUpdate: DateTimeNow(),
      userUpdate: user?.user?.nome || '',
      ...(motivo !== '' && { motivo }),
    }
    console.log(newCadidacy)
    await updateSubDocument('events', id, 'staff', idCandidacy, newCadidacy)

    const hasCheckinCheckout = setCheckinOrCheckout(statusCurrent, status)
    console.log(hasCheckinCheckout)
    if (hasCheckinCheckout.length > 0) {
      await updateArrayInSubDocument('events', id, 'staff', idCandidacy, 'workingHours', hasCheckinCheckout, true)
    }

    const newData = dataContext?.staff?.data.map((cand, index) => {
      if (idCandidacy === cand.id) {
        return { ...cand, status }
      }
      return cand
    })

    await setDataContext((prev) => ({ ...prev, staff: { ...prev.staff, data: newData } }))
    await SetHistoryEvent(id, user.user.nome, [
      `Candidatura Atualizada: ${nameColaborador}`,
      `Data: ${dateCandidacy}`,
      `Novo Status "${statusStaff[Number(status)].status}`,
      ...(motivo !== '' ? [`Motivo: ${motivo}`] : []),
    ])
    setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Candidatura Atualizada',
        msg: `Candidatura do colaborador ${nameColaborador} para o dia ${dateCandidacy} foi atualizada para "${statusStaff[Number(status)].status}" `,
      },
    ])
  }

  const UpdateStatusMultipleCandidacy = async (newStatus, ListCandidacys) => {
    // seta o status para 3
    console.log(dataContext?.staff?.data || [])

    const IdsCandidacys = Object.keys(ListCandidacys).filter((id) => ListCandidacys[id])

    const currentStatus = (dataContext?.staff?.data || [])
      .map((candidacy) => {
        if (IdsCandidacys.includes(candidacy.id)) {
          return candidacy.status
        } else {
          return null
        }
      })
      .filter((s) => s !== null)

    if (currentStatus.length === 0) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Nenhum colaborador checados',
          msg: `Selecione ao menos um colaborador para continuar a ação...`,
        },
      ])
    }

    const motivo = await checkStatusForJustify(currentStatus[0], newStatus)
    // se motivo for vazio ele retorna
    if (motivo === null) return

    const newCandidacy = {
      status: newStatus,
      dtUpdate: DateTimeNow(),
      userUpdate: user?.user?.nome,
      ...(motivo !== '' && { motivo }),
    }

    console.log(newCandidacy)

    let newData

    const promises = IdsCandidacys.map(async (idCandidacy) => {
      await updateSubDocument('events', id, 'staff', idCandidacy, newCandidacy)

      const hasCheckinCheckout = setCheckinOrCheckout(currentStatus[0], newStatus)
      console.log(hasCheckinCheckout)
      if (hasCheckinCheckout.length > 0) {
        await updateArrayInSubDocument('events', id, 'staff', idCandidacy, 'workingHours', hasCheckinCheckout, true)
      }
    })

    await Promise.all(promises).then(() => {
      newData = dataContext?.staff?.data.map((cand) => {
        if (IdsCandidacys.includes(cand.id)) {
          return { ...cand, status: newStatus }
        }
        return cand
      })
    })

    await setDataContext((prev) => ({ ...prev, staff: { ...prev.staff, data: newData } }))
    await SetHistoryEvent(
      id,
      user.user.nome,
      [
        `Candidaturas Atualizadas: ${IdsCandidacys.length} Selecionados`,
        `Novo Status "${statusStaff[Number(newStatus)].status}"`,
        ...(motivo !== '' ? [`Motivo: ${motivo}`] : []),
      ],
      { IdCandidaturas: IdsCandidacys },
    )

    const newChecks = Object.keys(ListCandidacys).reduce((acc, ids) => {
      acc[ids] = false
      return acc
    }, {})

    await setChecks(newChecks)

    return setAlerts((prev) => [
      ...prev,
      {
        type: 'success',
        title: 'Candidatura Atualizada',
        msg: `Candidatura de ${IdsCandidacys.length} Selecionados, mudados para "${statusStaff[Number(newStatus)].status}" `,
      },
    ])
  }

  const getHoursRegister = async () => {
    const getHours = await getSubDocuments('events', id, 'workingHours')
    setInfosContext((prev) => ({ ...prev, hoursRegistereds: getHours }))
  }

  const AddWorkingHours = async () => {
    const date = formContext.workingDate
    const time = formContext.workingTime
    const comment = formContext.workingComment

    const dateTime = `${DataComTraçoParaDataComBarra(date)} ${time}`
    const commentToTitle = TransformationforMask(comment, 'title')

    const newRegisterHour = schemaRegisterTimesPoints({ time: dateTime, comment: commentToTitle })

    const query = [{ field: 'status', operator: '>=', value: 5 }]

    const getStaff = await getSubDocumentsWithQuerys('events', id, 'staff', query)

    if (getStaff.length > 0) {
      try {
        await addSubDocument('events', id, 'workingHours', newRegisterHour)
        const rolesStatus = [6, 11] // só adiciona se não estiver nesses status
        const promises = getStaff.map(async (candidacy) => {
          if (!rolesStatus.includes(candidacy.status)) {
            await updateArrayInSubDocument('events', id, 'staff', candidacy.id, 'workingHours', [newRegisterHour], true)
          }
        })

        // Espera todas as promessas de atualização serem resolvidas
        await Promise.all(promises)
        await getHoursRegister()
        // Mensagem de sucesso
        await SetHistoryEvent(id, user.user.nome, `Marcação de Horas Adicionada`, {
          Registro: [newRegisterHour.time, newRegisterHour.comment],
        })
        setAlerts((prev) => [
          ...prev,
          {
            type: 'success',
            title: 'Horas Registradas',
            msg: `A Marcação de hora foi registrada para os colaboradores que estão presentes na staff.`,
          },
        ])
      } catch (error) {
        // Caso algum erro ocorra durante a atualização dos colaboradores
        setAlerts((prev) => [
          ...prev,
          {
            type: 'error',
            title: 'Erro ao registrar horas',
            msg: `Ocorreu um erro ao registrar as horas para os colaboradores: ${error.message}`,
          },
        ])
      }
    } else {
      setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Staff Não Iniciada',
          msg: `Para registrar um ponto, pelo menos um colaborador deve ter efetuado o check-in.`,
        },
      ])
    }
  }

  const DeleteWorkingHours = async (workingTime) => {
    console.log(workingTime)
    const idWorkingTime = workingTime.id

    delete workingTime.id

    const query = [{ field: 'status', operator: '>=', value: 5 }]

    const getStaff = await getSubDocumentsWithQuerys('events', id, 'staff', query)
    console.log(getStaff)
    console.log(workingTime)
    if (getStaff.length > 0) {
      try {
        await deleteSubDocument('events', id, 'workingHours', idWorkingTime)

        const promises = getStaff.map((candidacy) =>
          updateArrayInSubDocument('events', id, 'staff', candidacy.id, 'workingHours', [workingTime], false),
        )

        // Espera todas as promessas de atualização serem resolvidas
        await Promise.all(promises)
        await getHoursRegister()
        // Mensagem de sucesso
        await SetHistoryEvent(id, user.user.nome, `Marcação de Horas Deletada`, {
          Registro: [workingTime.time, workingTime.comment],
        })
        setAlerts((prev) => [
          ...prev,
          {
            type: 'success',
            title: 'Hora Deletada',
            msg: `A Marcação de hora foi deletada para os colaboradores que estão presentes na staff.`,
          },
        ])
      } catch (error) {
        // Caso algum erro ocorra durante a atualização dos colaboradores
        setAlerts((prev) => [
          ...prev,
          {
            type: 'error',
            title: 'Erro ao deletar horas',
            msg: `Ocorreu um erro ao registrar as horas para os colaboradores: ${error.message}`,
          },
        ])
      }
    } else {
      setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Staff Não Iniciada',
          msg: `Para registrar um ponto, pelo menos um colaborador deve ter efetuado o check-in.`,
        },
      ])
    }
  }

  const ReadSheetPayment = async () => {
    await SetHistoryEvent(id, user.user.nome, `Tentativa de importar planilha de pagamentos`)
    const file = formContext?.file_payment
    if (!file) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Arquivo não carregado',
          msg: `Não encontramos uma arquivo, carrege-o novamente e realize uma nova tentativa `,
        },
      ])
    }

    if (!file.name.includes('.xlsx') || !file.name.includes('.xls')) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Formato de arquivo incorreto',
          msg: `Selecione somente arquivo de excel, baixe-o novamente para uma nova tentativa `,
        },
      ])
    }

    const response = await ReadExcelFile(file)

    if (!response?.Pagamentos) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Aba "Pagamentos" não econtrada',
          msg: `Verifique o arquivo enviado e tente novamente`,
        },
      ])
    }

    const headerPagamentos = response.Pagamentos[0]
    const dataPagamentos = response.Pagamentos.slice(1)

    // verifica se existe a coluna Pagamento_Realizado
    const colPayment = headerPagamentos.indexOf('Pagamento_Realizado')
    // console.log(colPayment)
    if (colPayment === -1) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Coluna "Pagamento_Realizado" não econtrada',
          msg: `Verifique o arquivo enviado e tente novamente`,
        },
      ])
    }
    // verifica se existe a coluna Pagamento_Realizado
    const colUUID = headerPagamentos.indexOf('UUID')
    // console.log(colUUID)
    if (colUUID === -1) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Coluna "UUID" não econtrada',
          msg: `Verifique o arquivo enviado e tente novamente`,
        },
      ])
    }

    // verifica se a linhda de status de pagamento contem somente PAGO e PENDENTE
    // console.log(dataPagamentos)
    const paymentExist = dataPagamentos.some((pay) => {
      // console.log(pay)
      if (Array.isArray(pay) && pay.length > 0) {
        const value = String(pay[colPayment]).toLocaleLowerCase()
        // console.log(value)
        return value !== 'pago' && value !== 'pendente'
      }
      return false
    })
    // console.log(paymentExist)
    // verifica se os valores estão corretos

    if (paymentExist) {
      return setAlerts((prev) => [
        ...prev,
        {
          type: 'error',
          title: 'Valores na coluna "Pagamento_Realizado" Incorretos',
          msg: `Encontramos valores incorretos na coluna "Pagamento_Realizado", só aceitamos os valores: "pago" e "pendente"`,
        },
      ])
    }

    // colete uuid e status de pagamento
    const newPayments = dataPagamentos
      .map((row) => {
        const uuid = row[colUUID]
        const status = row[colPayment]
        if (status === 'pago') {
          return uuid
        } else {
          return null
        }
      })
      .filter((uuid) => uuid !== null)

    // realiza o pagamento para as candidaturas com o uuid e status 9 (Pendente Pagamento)
    const queryStaff = [{ field: 'status', operator: '==', value: 9 }]

    const paymentsSuccess = []

    const promises = newPayments.map(async (uuid) => {
      const newCandidacy = { status: 10 }
      const newQueryStaff = { field: 'uuid', operator: '==', value: uuid }
      const candidacys = await getSubDocumentsWithQuerys('events', id, 'staff', [...queryStaff, newQueryStaff])

      if (Array.isArray(candidacys) && candidacys.length > 0) {
        const idCandidacys = candidacys.map((cand) => cand.id)
        // Usar map em vez de forEach para criar uma lista de promessas
        const updatePromises = idCandidacys.map(async (idCandidacy) => {
          paymentsSuccess.push(idCandidacy)
          return updateSubDocument('events', id, 'staff', idCandidacy, newCandidacy)
        })
        // Aguarda todas as atualizações para cada candidacy
        return Promise.all(updatePromises)
      }
    })

    // Aguarda que todas as promessas sejam resolvidas
    Promise.all(promises)
      .then(() => {
        FetchGetdatas()
        if (paymentsSuccess.length > 0) {
          SetHistoryEvent(id, user.user.nome, 'Planilha de Pagamentos Importada', {
            Pagamentos_Realizados: paymentsSuccess,
          })
          setModalUploadPayments(false)
          setFormContext((prev) => ({ ...prev, file_payment: null }))
          return setAlerts((prev) => [
            ...prev,
            {
              type: 'success',
              title: 'Planilha de Pagamentos Importada',
              msg: `A Planilhas de pagamentos foi importada com sucesso, e os pagamentos foram realizados conforme a planilha.`,
            },
          ])
        } else {
          SetHistoryEvent(
            id,
            user.user.nome,
            'Planilha de Pagamentos importada com sucesso. Observação: todos os pagamentos já estavam marcados como realizados.',
          )
          setModalUploadPayments(false)
          setFormContext((prev) => ({ ...prev, file_payment: null }))
          return setAlerts((prev) => [
            ...prev,
            {
              type: 'success',
              title: 'Planilha de Pagamentos Importada',
              msg: `A Planilhas de pagamentos foi importada com sucesso. Observação: todos os pagamentos já estavam marcados como realizados.`,
            },
          ])
        }
      })
      .catch((error) => console.error('Erro nas atualizações:', error))
  }

  const FetchQueryDatas = async () => {
    await TryCatch(queryData, setLoading, setAlerts)
  }

  const FetchClearQueryData = async () => {
    await TryCatch(clearQueryData, setLoading, setAlerts)
  }

  const FetchGetdatas = async (pageQuery = null) => {
    await TryCatch(
      () => {
        getDatas({ pageQuery: page })
      },
      setLoading,
      setAlerts,
    )
  }

  const FetchGetInfos = async () => {
    await TryCatch(getInfos, setLoading, setAlerts)
  }

  const HandleUpdateStatusCandidacy = async (status, valuesCandidacy) => {
    await TryCatch(
      () => {
        UpdateStatusCandidacy(status, valuesCandidacy)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleUpdateStatusMultipleCandidacy = async (status, ListCandidacys) => {
    await TryCatch(
      async () => {
        await UpdateStatusMultipleCandidacy(status, ListCandidacys)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleFavoriteColaborator = async (uuidColab) => {
    await TryCatch(
      () => {
        favoriteColaborator(uuidColab)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleBlockUnBlockColaborator = async (uuidColab) => {
    await TryCatch(
      () => {
        blockUnBlockColaborator(uuidColab)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleUpdateValuesInfoCandidacy = async (value) => {
    await TryCatch(
      () => {
        updateValuesInfoCandidacy(value)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleAddWorkingHours = async () => {
    await TryCatch(AddWorkingHours, setLoading, setAlerts)
  }

  const HandleDeleteWorkingHours = async (values) => {
    await TryCatch(
      () => {
        DeleteWorkingHours(values)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleInviteTeams = async (value) => {
    await TryCatch(
      () => {
        inviteTeams(value)
      },
      setLoading,
      setAlerts,
    )
  }

  const HandleExportExcelPayment = async () => {
    await TryCatch(
      () => {
        ExportAllStaffPayment(id, setAlerts)
      },
      setLoading,
      setAlerts,
    )
    await SetHistoryEvent(id, user.user.nome, `Planilha de Pagamentos extraida`)
  }

  const HandleExportAllWorkingTimeRegister = async () => {
    await TryCatch(
      () => {
        ExportAllWorkingTimeRegister(id, setAlerts)
      },
      setLoading,
      setAlerts,
    )
    await SetHistoryEvent(id, user.user.nome, `Planilha de Registro de Horas Exportada`)
  }

  const HandleReadSheetPayment = async () => {
    await TryCatch(ReadSheetPayment, setLoading, setAlerts)
  }

  const HandleGetDataFavorites = async () => {
    await TryCatch(getDataFavorites, setLoading, setAlerts)
  }

  const HandleAddCandidacy = async (values) => {
    await TryCatch(
      async () => {
        await addCandidacy(values)
      },
      setLoading,
      setAlerts,
    )
  }

  const ActionForButtonsCandidates = (value) => {
    return {
      setAccept: () => {
        HandleUpdateStatusCandidacy(2, value)
      },
      setReject: () => {
        HandleUpdateStatusCandidacy(1, value)
      },
      setCheckin: () => {
        HandleUpdateStatusCandidacy(5, value)
      },
      setCheckout: () => {
        HandleUpdateStatusCandidacy(7, value)
      },
      setPay: () => {
        HandleUpdateStatusCandidacy(10, value)
      },
      setCancel: () => {
        HandleUpdateStatusCandidacy(11, value)
      },
      setRecover: () => {
        HandleUpdateStatusCandidacy(2, value)
      },
    }
  }

  const ActionForButtonsMultiplesCandidates = (Idschecks) => {
    return {
      setAccept: async () => {
        await HandleUpdateStatusMultipleCandidacy(2, Idschecks)
      },
      setReject: async () => {
        await HandleUpdateStatusMultipleCandidacy(1, Idschecks)
      },
      setCheckin: async () => {
        await HandleUpdateStatusMultipleCandidacy(5, Idschecks)
      },
      setCheckout: async () => {
        await HandleUpdateStatusMultipleCandidacy(7, Idschecks)
      },
      setPay: async () => {
        await HandleUpdateStatusMultipleCandidacy(10, Idschecks)
      },
      setCancel: async () => {
        await HandleUpdateStatusMultipleCandidacy(11, Idschecks)
      },
      setRecover: async () => {
        await HandleUpdateStatusMultipleCandidacy(2, Idschecks)
      },
    }
  }

  const ActionForModalInfoColaborator = () => {
    return {
      setFavorite: (uuid) => {
        HandleFavoriteColaborator(uuid)
      },
      setBlocked: (uuid) => {
        HandleBlockUnBlockColaborator(uuid)
      },
    }
  }

  const SetValuesInfoCandidacy = (values) => {
    setFormContext((prev) => ({ ...prev, ...values }))
    setModalInfoCandidacy(true)
  }

  const [teamsList, setTeamsList] = useState([])

  useEffect(() => {
    const fetchTeamsList = async () => {
      const filteredTeams = (infosContext?.teams || []).filter((team) => team.id === formContext?.teamId)

      let newFilteresTeams = filteredTeams.length > 0 ? filteredTeams[0].teams || [] : []

      const isObjeto =
        typeof newFilteresTeams === 'object' && !Array.isArray(newFilteresTeams) && newFilteresTeams !== null

      if (isObjeto) {
        newFilteresTeams = Object.keys(newFilteresTeams).map((key) => newFilteresTeams[key])
      }

      const newFilteresTeamsEnriched = []

      const promise = newFilteresTeams.map(async (colab) => {
        const exist = await getDocumentsWithQuery('colaboradores', 'cpf', '==', colab.cpf)
        newFilteresTeamsEnriched.push({ ...colab, exist: exist.length > 0 })
      })

      await Promise.all(promise)

      setTeamsList(newFilteresTeamsEnriched)
    }

    fetchTeamsList()
  }, [infosContext?.teams, formContext?.teamId])

  useEffect(() => {
    const updateData = async (newPage) => {
      if (newPage !== '') {
        await FetchGetInfos()
        await FetchGetdatas(newPage)
      }
    }
    updateData(page)
  }, [page])

  useEffect(() => {
    const allChecks = {}

    dataContext?.staff?.data.forEach((value) => {
      return (allChecks[value.id] = checkAll)
    })

    setChecks(allChecks) // Atualiza para marcar todos
  }, [checkAll])

  useEffect(() => {
    if (Object.values(checks).filter((check) => check === true).length <= 0) {
      setCheckall(false)
    }

    console.log(formContext)
  }, [checks])

  useEffect(() => {
    if (formContext?.CandidacyRegisterWorkingHours && formContext?.CandidacyRegisterWorkingHours !== '') {
      getHoursRegister()
    }
    console.log(formContext)
    console.log(infosContext)
  }, [formContext])

  return {
    id,
    page,
    setPage,
    loading,
    filter,
    setFilter,
    checks,
    checkAll,
    setCheckall,
    setChecks,
    formContext,
    setFormContext,
    FetchGetInfos,
    FetchGetdatas,
    infosContext,
    setInfosContext,
    dataContext,
    FetchQueryDatas,
    FetchClearQueryData,
    HandleUpdateStatusCandidacy,
    ActionForButtonsCandidates,
    ActionForButtonsMultiplesCandidates,
    modalInvite,
    setModalInvite,
    modalInfoCandidacy,
    setModalInfoCandidacy,
    SetValuesInfoCandidacy,
    ActionForModalInfoColaborator,
    checkFavoriteColaborator,
    HandleUpdateValuesInfoCandidacy,
    exportAllData,
    criar1000Candidaturas,
    teamsList,
    HandleInviteTeams,
    HandleExportExcelPayment,
    HandleReadSheetPayment,
    modalUploadPayments,
    setModalUploadPayments,
    modalRegisterWorkingHours,
    setModalRegisterWorkingHours,
    HandleAddWorkingHours,
    HandleDeleteWorkingHours,
    HandleExportAllWorkingTimeRegister,
    modalAdd,
    setModalAdd,
    HandleGetDataFavorites,
    searchFavorite,
    setSearchFavorite,
    HandleAddCandidacy,
  }
}
