import React from 'react'
import { Outlet } from 'react-router-dom'

import { Page } from '../../../components/layouts/page'
import { SideBar } from '../../../components/layouts/sidebar'
import { Icon } from '../../../components/icons'
import { Path } from '../../../router/paths'
import { useClientEventsSideBar } from '../../client/events/_hooks/useClientEventsSideBar'

export const ClientLayoutSideBarEvents = () => {
  const {
    id,
    location,
    formContext,
    HandleReOpenEvent,
    HandleOpenAllJobs,
    HandleCloseAllJobs,
    // HandleExportExcelPayment,
    loading,
    HandleDeleteEvent,
  } = useClientEventsSideBar()

  return (
    <>
      <Page.Content
        className=""
        style={{
          marginTop: 80,
          marginLeft: 80,
          marginRight: 10,
          marginBottom: 500,
        }}
      >
        <Outlet />
      </Page.Content>

      <Page.SideBar>
        <SideBar.Root>
          <SideBar.List>
            {!loading ? (
              <SideBar.Item title={'Voltar'} link={Path.ClientEvents} loading={loading}>
                <Icon size={30}>arrowLeft</Icon>
              </SideBar.Item>
            ) : (
              <SideBar.Item title={'Aguarde Conclusão do carregamento...'}>
                <Icon size={30} className="has-text-grey-lighter">
                  arrowLeft
                </Icon>
              </SideBar.Item>
            )}
            <SideBar.Item
              title={'Informações'}
              link={Path.ClientEditEvents(id)}
              activate={location.pathname === Path.ClientEditEvents(id)}
              loading={loading}
            >
              <Icon size={30}>info</Icon>
            </SideBar.Item>
            <SideBar.Item
              title={'Vagas'}
              link={Path.ClientjobOffersEvents(id)}
              activate={location.pathname === Path.ClientjobOffersEvents(id)}
              loading={loading}
            >
              <Icon size={30}>job</Icon>
            </SideBar.Item>
            <SideBar.Item
              title={'Staff'}
              link={Path.ClientStaffEvents(id)}
              activate={location.pathname === Path.ClientStaffEvents(id)}
              loading={loading}
            >
              <Icon size={30}>users</Icon>
            </SideBar.Item>
            <SideBar.Item
              title={'Históricos'}
              link={Path.ClientHistoryEvent(id)}
              activate={location.pathname === Path.ClientHistoryEvent(id)}
              loading={loading}
            >
              <Icon size={30}>history</Icon>
            </SideBar.Item>

            <SideBar.Space size={100}></SideBar.Space>

            <SideBar.Item
              title={formContext?.statusEvent === 'open' ? 'Finalizar Evento' : 'Finalização do evento'}
              link={Path.ClientStaffFinally(id)}
              activate={location.pathname === Path.ClientStaffFinally(id)}
              loading={loading}
            >
              <Icon size={30} className={formContext?.statusEvent === 'open' ? 'has-text-danger' : 'has-text-dark'}>
                calendar-close
              </Icon>
            </SideBar.Item>

            {formContext.statusEvent !== 'deleted' && (
              <>
                {formContext.statusEvent === 'closed' && (
                  <>
                    <SideBar.Button
                      title="Reabrir Evento"
                      link={Path.ClientUsers}
                      action={HandleReOpenEvent}
                      loading={loading}
                    >
                      <Icon size={30} className="has-text-success">
                        calendar
                      </Icon>
                      <Icon size={20} className="has-text-success mr-1" margin={-24}>
                        check
                      </Icon>
                    </SideBar.Button>
                  </>
                )}

                {formContext.statusEvent === 'open' && (
                  <>
                    <SideBar.Button
                      title={formContext.openAllJobs ? 'Fechar Todas as Candidaturas' : 'Abrir Todas as Candidaturas'}
                      link={Path.ClientUsers}
                      action={formContext.openAllJobs ? HandleCloseAllJobs : HandleOpenAllJobs}
                      loading={loading}
                    >
                      <Icon size={30} className={formContext.openAllJobs ? 'has-text-danger' : 'has-text-success'}>
                        {formContext.openAllJobs ? 'lock' : 'unlock'}
                      </Icon>
                    </SideBar.Button>
                    <SideBar.Button
                      title={'Excluir Evento'}
                      link={Path.ClientUsers}
                      loading={loading}
                      action={() => {
                        HandleDeleteEvent(id, formContext?.name)
                      }}
                    >
                      <Icon size={30} className={'has-text-danger'}>
                        trash
                      </Icon>
                    </SideBar.Button>
                  </>
                )}
              </>
            )}
          </SideBar.List>
        </SideBar.Root>
      </Page.SideBar>

      {/* Componente do REACT DOM que move a pagina para cima ao trocar de paginas, só funciona para as paginas que estão em children  */}
    </>
  )
}
