import React from 'react'

export const InputReadOnly = ({
  children,
  type = 'text',
  className = '',
  color = 'is-light',
  size = '',
  isLoading = false,
  disabled = null,
}) => {
  return (
    <input
      className={`input ${color} ${size} ${isLoading ? 'is-loading' : ''} ${className}`}
      type={type}
      value={children}
      disabled={disabled}
      readOnly={true}
    />
  )
}
