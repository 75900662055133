import React from 'react'

import { Column } from '../../../../components/layouts/columns'
import { Button } from '../../../../components/ui/button'
import { Input } from '../../../../components/ui/input'
import { Select } from '../../../../components/ui/select'
import { Path } from '../../../../router/paths'
import { Table } from '../../../../components/ui/table'
import { useCollaboratorsForm } from '../_hooks/useCollaboratorsForm'
import { Icon } from '../../../../components/icons'
import { TransformationforMask } from '../../../../utils/masks'
import { File } from '../../../../components/ui/file'
import { ValidateCollaboratorModal } from '../_modal/ValidateCollaboratorModal'

export const CollaboratorsForm = () => {
  const {
    loading,
    formContext,
    setFormContext,
    openModal,
    handleOpenValidateModal,
    search,
    setSearch,
    position,
    isPositionEmpty,
    setPosition,
    positionList,
    getPositionStatusType,
    getConsentStatusType,
    getDocumentStatusType,
    handleMainPosition,
    positionsAdded,
    blockUnblockPosition,
    consents,
    documents,
    addPositionToList,
    deletePositionFromList,
    approvePosition,
    submitFormDisabled,
    handleValidateCollaboratorCheckbox,
    emergencyContactsGroup,
    emergencyContactsGroupCount,
    handleAddEmergencyContact,
    handleDeleteEmergencyContact,
    handleEmergencyFieldsChange,
  } = useCollaboratorsForm()

  return (
    <>
      <ValidateCollaboratorModal active={openModal} setActive={handleOpenValidateModal} />
      <Column.Root>
        <Column.Content className="is-one-quarter has-text-centered">
          {/* <Column.Content className="has-text-centered is-5"> */}
          <p>Foto de Perfil</p>
          {formContext?.url_foto ? (
            String(formContext?.url_foto).includes('https://') ? (
              <img
                alt="url_foto"
                src={formContext?.url_foto}
                style={{ width: '50%', borderRadius: '50%' }}
                className="has-shadow"
              ></img>
            ) : (
              <img
                alt="url_foto"
                src={formContext?.url_foto && URL.createObjectURL(formContext?.url_foto)}
                style={{ width: '50%', borderRadius: '50%' }}
                className="has-shadow"
              ></img>
            )
          ) : (
            <></>
          )}

          <File.Root className="mt-5">
            <File.Input setFile={setFormContext} field={'url_foto'} accepts="image/*" />
            <File.Contents>
              <File.Label>
                {formContext?.url_foto?.name
                  ? String(formContext?.url_foto?.name).slice(0, 20).replace('.png', '') +
                    '.' +
                    String(formContext?.url_foto?.type).replace('image/', '')
                  : 'Escolha uma imagem...'}
              </File.Label>
            </File.Contents>
          </File.Root>
          {/* </Column.Content> */}
        </Column.Content>
        <Column.Content>
          <div className="field">
            <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="nome">
              Nome
            </Input.Label>
            <div className="is-flex w-100 gap-2">
              {formContext?.validate === true && (
                <input
                  className="mr-1"
                  type="checkbox"
                  onChange={() => handleValidateCollaboratorCheckbox('name', 'Nome do colaborador', formContext?.nome)}
                  checked={formContext?.inEdition?.name}
                />
              )}
              <Input.Prompt
                id="nome"
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'nome'}
                required={true}
                disabled={false}
              ></Input.Prompt>
            </div>
          </div>

          <Column.Root className="mt-3">
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="nomeSocial">
                  Nome Social
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  {formContext?.validate === true && (
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() =>
                        handleValidateCollaboratorCheckbox('socialName', 'Nome social', formContext?.nomeSocial)
                      }
                      checked={formContext?.inEdition?.socialName}
                    />
                  )}
                  <Input.Prompt
                    id="nomeSocial"
                    className="is-rounded"
                    value={formContext}
                    setValue={setFormContext}
                    field={'nomeSocial'}
                    required={true}
                    disabled={false}
                  >
                    {formContext?.nomeSocial}
                  </Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="cpf">
                  CPF
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  {formContext?.validate === true && (
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() => handleValidateCollaboratorCheckbox('cpf', 'CPF', formContext?.cpf)}
                      checked={formContext?.inEdition?.cpf}
                    />
                  )}
                  <Input.Prompt
                    id="cpf"
                    className="is-rounded"
                    value={formContext}
                    setValue={setFormContext}
                    field={'cpf'}
                    maskSetValue={'cpf'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="estadoCivil">
                  Estado Civil
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  {formContext?.validate === true && (
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() =>
                        handleValidateCollaboratorCheckbox('civilState', 'Estado civil', formContext?.estadoCivil)
                      }
                      checked={formContext?.inEdition?.civilState}
                    />
                  )}
                  <Select.Root>
                    <Select.Content
                      id="estadoCivil"
                      color="is-grey"
                      className="is-fullwidth is-rounded"
                      value={formContext}
                      setValue={setFormContext}
                      field={'estadoCivil'}
                      disabled={false}
                      required={true}
                    >
                      <Select.Option
                        value={'Selecione uma opção'}
                        title={'Selecione uma opção'}
                        selected={true}
                        disabled={true}
                      />
                      <Select.Option value={'Solteiro(a)'} title={'Solteiro(a)'} />
                      <Select.Option value={'Casado(a)'} title={'Casado(a)'} />
                      <Select.Option value={'Divorcidado(a)'} title={'Divorcidado(a)'} />
                      <Select.Option value={'Viúvo(a)'} title={'Viúvo(a)'} />
                    </Select.Content>
                  </Select.Root>
                </div>
              </div>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="dtNascimento">
                  Data de nascimento
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  {formContext?.validate === true && (
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() =>
                        handleValidateCollaboratorCheckbox('birthday', 'Data de nascimento', formContext?.dtNascimento)
                      }
                      checked={formContext?.inEdition?.birthday}
                    />
                  )}
                  <Input.Prompt
                    id="dtNascimento"
                    className="is-rounded"
                    value={formContext}
                    setValue={setFormContext}
                    field={'dtNascimento'}
                    required={true}
                    disabled={false}
                    type="date"
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="documento">
                  Documento
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  {formContext?.validate === true && (
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() =>
                        handleValidateCollaboratorCheckbox('document', 'Documento', formContext?.documento)
                      }
                      checked={formContext?.inEdition?.document}
                    />
                  )}
                  <Input.Prompt
                    id="documento"
                    className="is-rounded"
                    value={formContext}
                    setValue={setFormContext}
                    field={'documento'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="tipoDocumento">
                  Tipo de Documento
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  {formContext?.validate === true && (
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() =>
                        handleValidateCollaboratorCheckbox('docType', 'Tipo de documento', formContext?.tipoDocumento)
                      }
                      checked={formContext?.inEdition?.docType}
                    />
                  )}
                  <Select.Root>
                    <Select.Content
                      id="tipoDocumento"
                      color="is-grey"
                      className="is-fullwidth is-rounded"
                      value={formContext}
                      setValue={setFormContext}
                      field={'tipoDocumento'}
                      required={true}
                      disabled={false}
                    >
                      <Select.Option
                        value={'Selecione uma opção'}
                        title={'Selecione uma opção'}
                        selected={true}
                        disabled={true}
                      />
                      <Select.Option
                        value={'RNE ou CRNM'}
                        title={'Documento de Identidade para Estrangeiros (RNE ou CRNM)'}
                      />
                      <Select.Option value={'CNH'} title={'CNH (Carteira Nacional de Habilitação)'} />
                      <Select.Option value={'RG'} title={'RG (Registro Geral)'} />
                    </Select.Content>
                  </Select.Root>
                </div>
              </div>
            </Column.Content>
          </Column.Root>
        </Column.Content>
      </Column.Root>
      <Column.Root>
        <Column.Content className="is-full">
          <Column.Root className="mt-3">
            <Column.Content>
              <h2 className="subtitle is-size-4">Endereço</h2>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="logradouro">
                  Logradouro
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  {formContext?.validate === true && (
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() =>
                        handleValidateCollaboratorCheckbox('street', 'Logradouro', formContext?.logradouro)
                      }
                      checked={formContext?.inEdition?.street}
                    />
                  )}
                  <Input.Prompt
                    id="logradouro"
                    className="is-rounded"
                    value={formContext}
                    setValue={setFormContext}
                    field={'logradouro'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="numeroEndereco">
                  Número
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  {formContext?.validate === true && (
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() =>
                        handleValidateCollaboratorCheckbox('number', 'Número do endereço', formContext?.numeroEndereco)
                      }
                      checked={formContext?.inEdition?.number}
                    />
                  )}
                  <Input.Prompt
                    id="numeroEndereco"
                    className="is-rounded"
                    value={formContext}
                    setValue={setFormContext}
                    field={'numeroEndereco'}
                    maskSetValue="numero"
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="bairro">
                  Bairro
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  {formContext?.validate === true && (
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() => handleValidateCollaboratorCheckbox('district', 'Bairro', formContext?.bairro)}
                      checked={formContext?.inEdition?.district}
                    />
                  )}
                  <Input.Prompt
                    id="bairro"
                    className="is-rounded"
                    value={formContext}
                    setValue={setFormContext}
                    field={'bairro'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="cep">
                  CEP
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  {formContext?.validate === true && (
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() => handleValidateCollaboratorCheckbox('zipcode', 'CEP', formContext?.cep)}
                      checked={formContext?.inEdition?.zipcode}
                    />
                  )}
                  <Input.Prompt
                    id="cep"
                    className="is-rounded"
                    value={formContext}
                    setValue={setFormContext}
                    field={'cep'}
                    maskValue={'cep'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="cidade">
                  Cidade
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  {formContext?.validate === true && (
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() => handleValidateCollaboratorCheckbox('city', 'Cidade', formContext?.cidade)}
                      checked={formContext?.inEdition?.city}
                    />
                  )}
                  <Input.Prompt
                    id="cidade"
                    className="is-rounded"
                    value={formContext}
                    setValue={setFormContext}
                    field={'cidade'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="cidade">
                  UF
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  {formContext?.validate === true && (
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() => handleValidateCollaboratorCheckbox('state', 'UF', formContext?.uf)}
                      checked={formContext?.inEdition?.state}
                    />
                  )}
                  <Select.Root>
                    <Select.Content
                      color="is-grey"
                      className="is-fullwidth is-rounded"
                      value={formContext}
                      setValue={setFormContext}
                      field={'uf'}
                      disabled={false}
                      required={true}
                    >
                      <Select.Option
                        value={'Selecione uma opção'}
                        title={'Selecione uma opção'}
                        selected={true}
                        disabled={true}
                      />
                      <Select.Option value={'AC'} title={'AC'} />
                      <Select.Option value={'AL'} title={'AL'} />
                      <Select.Option value={'AP'} title={'AP'} />
                      <Select.Option value={'AM'} title={'AM'} />
                      <Select.Option value={'BA'} title={'BA'} />
                      <Select.Option value={'CE'} title={'CE'} />
                      <Select.Option value={'DF'} title={'DF'} />
                      <Select.Option value={'ES'} title={'ES'} />
                      <Select.Option value={'GO'} title={'GO'} />
                      <Select.Option value={'MA'} title={'MA'} />
                      <Select.Option value={'MT'} title={'MT'} />
                      <Select.Option value={'MS'} title={'MS'} />
                      <Select.Option value={'MG'} title={'MG'} />
                      <Select.Option value={'PA'} title={'PA'} />
                      <Select.Option value={'PB'} title={'PB'} />
                      <Select.Option value={'PR'} title={'PR'} />
                      <Select.Option value={'PE'} title={'PE'} />
                      <Select.Option value={'PI'} title={'PI'} />
                      <Select.Option value={'RJ'} title={'RJ'} />
                      <Select.Option value={'RN'} title={'RN'} />
                      <Select.Option value={'RS'} title={'RS'} />
                      <Select.Option value={'RO'} title={'RO'} />
                      <Select.Option value={'RR'} title={'RR'} />
                      <Select.Option value={'SC'} title={'SC'} />
                      <Select.Option value={'SP'} title={'SP'} />
                      <Select.Option value={'SE'} title={'SE'} />
                      <Select.Option value={'TO'} title={'TO'} />
                    </Select.Content>
                  </Select.Root>
                </div>
              </div>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <h2 className="subtitle is-size-4">Contato</h2>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="celular">
                  Celular
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  {formContext?.validate === true && (
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() => handleValidateCollaboratorCheckbox('phone', 'Celular', formContext?.celular)}
                      checked={formContext?.inEdition?.phone}
                    />
                  )}
                  <Input.Prompt
                    id="celular"
                    className="is-rounded"
                    value={formContext}
                    setValue={setFormContext}
                    field={'celular'}
                    maskSetValue={'tel'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="email">
                  E-mail
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  {formContext?.validate === true && (
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() => handleValidateCollaboratorCheckbox('email', 'E-mail', formContext?.email)}
                      checked={formContext?.inEdition?.email}
                    />
                  )}
                  <Input.Prompt
                    id="email"
                    className="is-rounded"
                    value={formContext}
                    setValue={setFormContext}
                    field={'email'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <h3 className="subtitle is-size-6">Contatos de Emergência</h3>
            </Column.Content>
          </Column.Root>

          <Column.Root>
            <Column.Content>
              {emergencyContactsGroup?.map((emergencyContactGroup, index) => (
                <Column.Root className="mt-3" key={index}>
                  <Column.Content>
                    <div className="field">
                      <Input.Label
                        className="has-text-grey pl-5"
                        style={{ marginBottom: 0 }}
                        htmlFor={'nomeContatoEmergencia-' + index}
                      >
                        Nome
                      </Input.Label>
                      <div className="is-flex w-100 gap-2">
                        {formContext?.validate === true && (
                          <input
                            className="mr-1"
                            type="checkbox"
                            onChange={() =>
                              handleValidateCollaboratorCheckbox('name', 'Nome', emergencyContactGroup.name, index)
                            }
                            checked={formContext?.inEdition?.emergencyContacts[index]?.name}
                          />
                        )}
                        <input
                          id={'nomeContatoEmergencia-' + index}
                          className={`input is-rounded`}
                          type={'text'}
                          name={'name'}
                          value={emergencyContactGroup.name}
                          onChange={(e) => handleEmergencyFieldsChange(index, e)}
                          required={true}
                        />
                      </div>
                    </div>
                  </Column.Content>
                  <Column.Content>
                    <div className="field">
                      <Input.Label
                        className="has-text-grey pl-5"
                        style={{ marginBottom: 0 }}
                        htmlFor={'parentescoContatoEmergencia-' + index}
                      >
                        Parentesco
                      </Input.Label>
                      <div className="is-flex w-100 gap-2">
                        {formContext?.validate === true && (
                          <input
                            className="mr-1"
                            type="checkbox"
                            onChange={() =>
                              handleValidateCollaboratorCheckbox(
                                'kinship',
                                'Parentesco',
                                emergencyContactGroup.kinship,
                                index,
                              )
                            }
                            checked={formContext?.inEdition?.emergencyContacts[index]?.kinship}
                          />
                        )}
                        <Select.Root>
                          <div className={`select is-rounded`}>
                            <select
                              id={'parentescoContatoEmergencia-' + index}
                              value={emergencyContactGroup.kinship}
                              name={'kinship'}
                              onChange={(e) => handleEmergencyFieldsChange(index, e)}
                              required={true}
                            >
                              <Select.Option
                                value={'Selecione uma opção'}
                                title={'Selecione uma opção'}
                                selected={false}
                                disabled={true}
                              />
                              <Select.Option value={'Pai'} title={'Pai'} />
                              <Select.Option value={'Mãe'} title={'Mãe'} />
                              <Select.Option value={'Filho(a)'} title={'Filho(a)'} />
                              <Select.Option value={'Irmão(ã)'} title={'Irmão(ã)'} />
                              <Select.Option value={'Avô(ó)'} title={'Avô(ó)'} />
                              <Select.Option value={'Neto(a)'} title={'Neto(a)'} />
                              <Select.Option value={'Tio(a)'} title={'Tio(a)'} />
                              <Select.Option value={'Sobrinho(a)'} title={'Sobrinho(a)'} />
                              <Select.Option value={'Primo(a)'} title={'Primo(a)'} />
                              <Select.Option value={'Padrasto(a)'} title={'Padrasto(a)'} />
                              <Select.Option value={'Enteado(a)'} title={'Enteado(a)'} />
                              <Select.Option value={'Cunhado(a)'} title={'Cunhado(a)'} />
                              <Select.Option value={'Sogro(a)'} title={'Sogro(a)'} />
                              <Select.Option value={'Genro/Nora'} title={'Genro/Nora'} />
                              <Select.Option value={'Bisavô(ó)'} title={'Bisavô(ó)'} />
                              <Select.Option value={'Bisneto(a)'} title={'Bisneto(a)'} />
                              <Select.Option value={'Padrinho/Madrinha'} title={'Padrinho/Madrinha'} />
                              <Select.Option value={'Afilhado(a)'} title={'Afilhado(a)'} />
                              <Select.Option value={'Cônjuge'} title={'Cônjuge'} />
                              <Select.Option value={'Noivo(a)'} title={'Noivo(a)'} />
                            </select>
                          </div>
                          {/* <Select.Content
                        id={'parentescoContatoEmergencia-' + index}
                        className="is-rounded"
                        value={emergencyContactsGroup}
                        setValue={setEmergencyContactsGroup}
                        field={'parentescoContatoEmergencia-' + index}
                        disabled={false}
                      ></Select.Content> */}
                        </Select.Root>
                      </div>
                    </div>
                  </Column.Content>
                  <Column.Content>
                    <div className="field">
                      <Input.Label
                        className="has-text-grey pl-5"
                        style={{ marginBottom: 0 }}
                        htmlFor={'celularContatoEmergencia-' + index}
                      >
                        Celular
                      </Input.Label>
                      <div className="is-flex w-100 gap-2">
                        {formContext?.validate === true && (
                          <input
                            className="mr-1"
                            type="checkbox"
                            onChange={() =>
                              handleValidateCollaboratorCheckbox('phone', 'Celular', emergencyContactGroup.phone, index)
                            }
                            checked={formContext?.inEdition?.emergencyContacts[index]?.phone}
                          />
                        )}
                        <input
                          id={'celularContatoEmergencia-' + index}
                          className={`input is-rounded`}
                          type={'text'}
                          name={'phone'}
                          value={TransformationforMask(emergencyContactGroup.phone, 'tel')}
                          onChange={(e) => handleEmergencyFieldsChange(index, e)}
                          required={true}
                        />
                      </div>
                    </div>
                  </Column.Content>
                  <Column.Content className="direction-rtl">
                    {emergencyContactsGroupCount > 1 && (
                      <Button.Bt
                        className=""
                        color="is-danger"
                        size={'is-small'}
                        colorText=""
                        action={() => handleDeleteEmergencyContact(index)}
                        loading={loading}
                      >
                        <Icon size={16}>trash</Icon>
                      </Button.Bt>
                    )}
                  </Column.Content>
                </Column.Root>
              ))}
            </Column.Content>
          </Column.Root>

          {!formContext?.validate && (
            <Column.Root>
              <Column.Content className="is-flex is-justify-content-flex-end">
                <Button.Bt
                  className="is-rounded"
                  color="is-success"
                  colorText="has-text-white"
                  action={handleAddEmergencyContact}
                  loading={loading}
                  disabled={emergencyContactsGroupCount < 2 && emergencyContactsGroup[0].name === ''}
                >
                  Adicionar Novo Contato
                </Button.Bt>
              </Column.Content>
            </Column.Root>
          )}

          <Column.Root className="mt-3">
            <Column.Content>
              <h2 className="subtitle is-size-4">Dados Bancários</h2>
            </Column.Content>
          </Column.Root>

          <Column.Root className="mt-3">
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="conta">
                  Conta
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  {formContext?.validate === true && (
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() => handleValidateCollaboratorCheckbox('account', 'Conta', formContext?.conta)}
                      checked={formContext?.inEdition?.account}
                    />
                  )}
                  <Input.Prompt
                    id="conta"
                    className="is-rounded"
                    value={formContext}
                    setValue={setFormContext}
                    field={'conta'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="agencia">
                  Agência
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  {formContext?.validate === true && (
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() => handleValidateCollaboratorCheckbox('agency', 'Agência', formContext?.agencia)}
                      checked={formContext?.inEdition?.agency}
                    />
                  )}
                  <Input.Prompt
                    id="agencia"
                    className="is-rounded"
                    value={formContext}
                    setValue={setFormContext}
                    field={'agencia'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="banco">
                  Banco
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  {formContext?.validate === true && (
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() => handleValidateCollaboratorCheckbox('bank', 'Banco', formContext?.banco)}
                      checked={formContext?.inEdition?.bank}
                    />
                  )}
                  <Input.Prompt
                    id="banco"
                    className="is-rounded"
                    value={formContext}
                    setValue={setFormContext}
                    field={'banco'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
            <Column.Content>
              <div className="field">
                <Input.Label className="has-text-grey pl-5" style={{ marginBottom: 0 }} htmlFor="pix">
                  PIX
                </Input.Label>
                <div className="is-flex w-100 gap-2">
                  {formContext?.validate === true && (
                    <input
                      className="mr-1"
                      type="checkbox"
                      onChange={() => handleValidateCollaboratorCheckbox('pix', 'PIX', formContext?.pix)}
                      checked={formContext?.inEdition?.pix}
                    />
                  )}
                  <Input.Prompt
                    id="pix"
                    className="is-rounded"
                    value={formContext}
                    setValue={setFormContext}
                    field={'pix'}
                    required={true}
                    disabled={false}
                  ></Input.Prompt>
                </div>
              </div>
            </Column.Content>
          </Column.Root>

          {formContext?.validate && (
            <Column.Root>
              <Column.Content className="is-flex is-justify-content-flex-end">
                <Button.Bt
                  className="is-rounded"
                  color="is-success"
                  colorText="has-text-white"
                  action={handleOpenValidateModal}
                  loading={loading}
                  // disabled={}
                >
                  Validar campos
                  <Icon size={20}>check</Icon>
                </Button.Bt>
              </Column.Content>
            </Column.Root>
          )}
        </Column.Content>
      </Column.Root>

      {!formContext?.validate && (
        <>
          <Column.Root>
            <Column.Content>
              <Column.Root className="mt-3">
                <Column.Content>
                  <h2 id="positions" className="subtitle is-size-4">
                    Cargos
                  </h2>
                </Column.Content>
              </Column.Root>

              <Column.Root className="is-mobile mr-6 pr-2">
                <Column.Content>
                  <Input.Root className="mb-5">
                    <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
                      Cargos
                    </Input.Label>
                    <Select.Root>
                      <Select.Content
                        color="is-grey"
                        className="is-fullwidth is-rounded"
                        value={position}
                        setValue={setPosition}
                        disabled={false}
                      >
                        <Select.Search value={search} setValue={setSearch} />
                        {Object.keys(positionList).map((key, index) => {
                          return <Select.Option key={index} value={key} title={positionList[key].name} />
                        })}
                      </Select.Content>
                    </Select.Root>
                  </Input.Root>
                </Column.Content>
                <Column.Content size="is-1 mr-5">
                  <Button.Bt
                    className="mt-5 is-rounded"
                    color="is-dark"
                    colorText=""
                    action={() => {
                      addPositionToList(position)
                    }}
                    disabled={isPositionEmpty} // tava pegando false por default, verificar depois
                    loading={loading}
                  >
                    Adicionar
                  </Button.Bt>
                </Column.Content>
              </Column.Root>

              {Object.keys(positionsAdded).length > 0 && (
                <div className="box" style={{ marginTop: 0 }}>
                  <div className="table-container">
                    <Table.Root className="is-fullwidth has-text-centered mb-5 mt-2">
                      <Table.Head>
                        <Table.Row>
                          <Table.Cell>Nome</Table.Cell>
                          <Table.Cell>Principal</Table.Cell>
                          <Table.Cell>Status</Table.Cell>
                          <Table.Cell>Ações</Table.Cell>
                        </Table.Row>
                      </Table.Head>
                      <Table.Body>
                        {Object.keys(positionsAdded).map((positionAdded) => {
                          const positionId = positionsAdded[positionAdded].id

                          return (
                            <Table.Row key={positionId}>
                              <Table.Cell>{positionsAdded[positionAdded].name}</Table.Cell>
                              <Table.Cell>
                                <label className="radio" htmlFor={positionId + '-radio'}>
                                  {''}
                                </label>
                                <input
                                  id={positionId + '-radio'}
                                  type="radio"
                                  checked={positionsAdded[positionAdded].main}
                                  name="cargoPrincipal"
                                  onChange={() => handleMainPosition(positionId)}
                                />
                              </Table.Cell>
                              <Table.Cell>{getPositionStatusType(positionsAdded[positionAdded].status)}</Table.Cell>
                              <Table.Cell className="is-flex is-justify-content-center gap-2">
                                <Button.Content>
                                  <Button.Bt
                                    size="is-small"
                                    color={positionsAdded[positionAdded].status === 9 ? 'is-danger' : 'is-success'}
                                    action={() => blockUnblockPosition(positionId)}
                                    loading={loading}
                                  >
                                    <Icon size={15}>unlock</Icon>
                                  </Button.Bt>
                                </Button.Content>
                                <Button.Content>
                                  <Button.Bt
                                    size="is-small"
                                    color="is-success"
                                    action={() => approvePosition(positionId)}
                                    loading={loading}
                                  >
                                    <Icon size={15}>check</Icon>
                                  </Button.Bt>
                                </Button.Content>
                                <Button.Content>
                                  <Button.Bt
                                    size="is-small"
                                    color="is-danger"
                                    action={() => deletePositionFromList(positionId)}
                                    loading={loading}
                                  >
                                    <Icon size={15}>trash</Icon>
                                  </Button.Bt>
                                </Button.Content>
                              </Table.Cell>
                            </Table.Row>
                          )
                        })}
                      </Table.Body>
                    </Table.Root>
                  </div>
                </div>
              )}

              {Object.keys(consents).length > 0 && (
                <div className="box" style={{ marginTop: 12 }}>
                  <div className="table-container">
                    <Table.Root className="is-fullwidth has-text-centered mb-5 mt-2">
                      <Table.Head>
                        <Table.Row>
                          <Table.Cell>Nome</Table.Cell>
                          <Table.Cell>Data de assinatura</Table.Cell>
                          <Table.Cell>Status</Table.Cell>
                        </Table.Row>
                      </Table.Head>
                      <Table.Body>
                        {Object.keys(consents).map((consent) => {
                          const consentId = consents[consent].id

                          return (
                            <Table.Row key={consentId}>
                              <Table.Cell>{consents[consent].name}</Table.Cell>
                              <Table.Cell>{consents[consent].signDate}</Table.Cell>
                              <Table.Cell>{getConsentStatusType(consents[consent].status)}</Table.Cell>
                            </Table.Row>
                          )
                        })}
                      </Table.Body>
                    </Table.Root>
                  </div>
                </div>
              )}

              {Object.keys(consents).length > 0 && (
                <div className="box" style={{ marginTop: 12 }}>
                  <div className="table-container">
                    <Table.Root className="is-fullwidth has-text-centered mb-5 mt-2">
                      <Table.Head>
                        <Table.Row>
                          <Table.Cell>Nome</Table.Cell>
                          <Table.Cell>Data de envio</Table.Cell>
                          <Table.Cell>Status</Table.Cell>
                        </Table.Row>
                      </Table.Head>
                      <Table.Body>
                        {Object.keys(documents).map((document) => {
                          const documentId = documents[document].id

                          return (
                            <Table.Row key={documentId}>
                              <Table.Cell>{documents[document].name}</Table.Cell>
                              <Table.Cell>{documents[document].sendDate}</Table.Cell>
                              <Table.Cell>{getDocumentStatusType(documents[document].status)}</Table.Cell>
                            </Table.Row>
                          )
                        })}
                      </Table.Body>
                    </Table.Root>
                  </div>
                </div>
              )}
            </Column.Content>
          </Column.Root>
          <Column.Root className="mt-5 mb-6">
            <Column.Content>
              <Button.Root align="right">
                <Button.Content>
                  <Button.A color="is-light" link={Path.AdminCollaborators} loading={loading}>
                    Cancelar
                  </Button.A>
                </Button.Content>
                <Button.Content>
                  <Button.Bt
                    className=""
                    color="is-success"
                    colorText="has-text-white"
                    type={'submit'}
                    loading={loading}
                    disabled={!submitFormDisabled} // tava pegando false por default, verificar depois
                  >
                    Salvar
                  </Button.Bt>
                </Button.Content>
              </Button.Root>
            </Column.Content>
          </Column.Root>
        </>
      )}
    </>
  )
}
