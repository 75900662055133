import React from 'react'
import { Link } from 'react-router-dom'

export const NavbarDropdown = ({ children, title = '' }) => {
  return (
    <div className="navbar-item has-dropdown is-hoverable">
      <Link className="navbar-link">
        <p className="mx-5">{title}</p>
      </Link>
      <div className="navbar-dropdown">{children}</div>
    </div>
  )
}
