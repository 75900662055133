import { BrowserRouter, Routes, Route } from 'react-router-dom'

import { Login } from '../screens/login/_Login'
import { SelectAccount } from '../screens/login/_SelectUser'
import { useGlobalContext } from '../context/ContextGlobal'
import { LoginLayout } from '../screens/_layouts/login/loginLayout'
import { AdminLayout } from '../screens/_layouts/administration/adminLayoutNavBar'
import { AHome } from '../screens/administration/home'
import { Esquecisenha } from '../screens/login/_EsqueciSenha'
import { FirstAccess } from '../screens/login/_PrimeiroAcesso'
import { AdminSettingsLayout } from '../screens/_layouts/administration/settingsLayout'
import { AUsers } from '../screens/administration/settings/users'
import { AAddUsers } from '../screens/administration/settings/users/_AddUsers'
import { ASectors } from '../screens/administration/settings/sector'
import { AAddSectors } from '../screens/administration/settings/sector/_AddSector'
import { AEditUsers } from '../screens/administration/settings/users/_EditUsers'
import { AEditSectors } from '../screens/administration/settings/sector/_EditSector'
import { APlans } from '../screens/administration/settings/plans'
import { AAddPlans } from '../screens/administration/settings/plans/_AddPlans'
import { AEditPlans } from '../screens/administration/settings/plans/_EditPlans'
import { ADocuments } from '../screens/administration/settings/documents'
import { AAddDocuments } from '../screens/administration/settings/documents/_AddDocuments'
import { AEditDocuments } from '../screens/administration/settings/documents/_EditDocuments'
import { AConsents } from '../screens/administration/settings/consents'
import { AAddConsents } from '../screens/administration/settings/consents/_AddConsents'
import { AEditConsents } from '../screens/administration/settings/consents/_EditConsents'
import { AInformationEnterprise } from '../screens/administration/settings/information'
import { APositions } from '../screens/administration/settings/positions'
import { AAddPositions } from '../screens/administration/settings/positions/_AddPositions'
import { AEditPositions } from '../screens/administration/settings/positions/_EditPositions'
import { AClients } from '../screens/administration/clients'
import { AAddClients } from '../screens/administration/clients/_AddClients'
import { Path } from './paths'
import { AEditClients } from '../screens/administration/clients/_EditClients'
import { NotFound } from '../components/layouts/errors/notFound'
import { Protections } from '../components/layouts/protections'
import { AdminCollaborators } from '../screens/administration/collaborators'
import { AddAdminCollaborators } from '../screens/administration/collaborators/_AddCollaborators'
import { EditAdminCollaborator } from '../screens/administration/collaborators/_EditCollaborator'
import { ClientLayoutNavBar } from '../screens/_layouts/clients/clientLayoutNavBar'
import { ClientLayoutSideBarSettings } from '../screens/_layouts/clients/clientLayoutSideBarSettings'
import { ClientHome } from '../screens/client/home/home/home'
import { ClientInfoEnterprise } from '../screens/client/settings/InfoEnterprise'
import { ClientUsers, ClientAddUsers, ClientEditUsers } from '../screens/client/settings/users'
import { ClientAddSectors, ClientEditSectors, ClientSectors } from '../screens/client/settings/sectors'
import { ClientPayments } from '../screens/client/settings/payments'
import { ClientInformations } from '../screens/client/settings/Informations'
import { ClientEvents } from '../screens/client/events/clientEvents'
import { ClientAddEvents } from '../screens/client/events/clientAddEvents'
import { ClientLayoutSideBarEvents } from '../screens/_layouts/clients/clientLayoutSideBarEvents'
import { ClientEditEvents } from '../screens/client/events/clientEditEvents'
import { ClientEventsjobOffers } from '../screens/client/events/clientEventsjobOffers'
import { ClientEventsStaff } from '../screens/client/events/clientEventsStaff'
import { ClientTeams } from '../screens/client/teams/clientTeams'
import { ClientAddTeams } from '../screens/client/teams/clientAddTeams'
import { ClientEditTeams } from '../screens/client/teams/clientEditTeams'
import { ClientEventsFinally } from '../screens/client/events/clientEventsFinally'
import ScrollToTop from './scrollToTop'
import { ClientEventsHistory } from '../screens/client/events/clientEventsHistory'
import { ClientCitys } from '../screens/client/settings/citys'

export const MainRouter = () => {
  const { user } = useGlobalContext()

  const userPermissions = user?.user?.permissions ?? {}

  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        {/* LOGIN */}
        <Route path={Path.Login} element={<LoginLayout />}>
          <Route path={Path.Login} element={<Login />} />
          <Route path={Path.PasswordRecovery} element={<Esquecisenha />} />
          <Route path={Path.FirstAccess(':email')} element={<FirstAccess />} />
          <Route
            path={Path.SelectAccount}
            element={
              <Protections.Global required={user} permission={true}>
                <SelectAccount />
              </Protections.Global>
            }
          />
        </Route>
        {/* ADMINISTRATION */}
        <Route path={Path.AdministrationHome} element={<AdminLayout />}>
          <Route
            path={Path.AdministrationHome}
            element={
              <Protections.Global required={user?.user?.type === 'admin'} permission={true}>
                <AHome />
              </Protections.Global>
            }
          />
          <Route
            path={Path.AClients}
            element={
              <Protections.Global
                required={user?.user?.type === 'admin'}
                permission={userPermissions?.clientes_vizualizar}
              >
                <AClients />
              </Protections.Global>
            }
          />
          <Route
            path={Path.AAdicionarClientes}
            element={
              <Protections.Global required={user?.user?.type === 'admin'} permission={userPermissions?.clientes_criar}>
                <AAddClients />
              </Protections.Global>
            }
          />
          <Route
            path={Path.AdminClientEdit(':id')}
            element={
              <Protections.Global required={user?.user?.type === 'admin'} permission={userPermissions?.clientes_editar}>
                <AEditClients />
              </Protections.Global>
            }
          />
          {/* Contributor */}
          <Route
            path={Path.AdminCollaborators}
            element={
              <Protections.Global
                required={user?.user?.type === 'admin'}
                permission={userPermissions?.colaboradores_vizualizar}
              >
                <AdminCollaborators />
              </Protections.Global>
            }
          />
          <Route
            path={Path.AddAdminCollaborators}
            element={
              <Protections.Global
                required={user?.user?.type === 'admin'}
                permission={userPermissions?.colaboradores_criar}
              >
                <AddAdminCollaborators />
              </Protections.Global>
            }
          />
          <Route
            path={Path.EditAdminCollaborator(':id')}
            element={
              <Protections.Global
                required={user?.user?.type === 'admin'}
                permission={userPermissions?.colaboradores_editar}
              >
                <EditAdminCollaborator />
              </Protections.Global>
            }
          />
          {/* SETTINGS */}
          <Route path={Path.ASettings} element={<AdminSettingsLayout />}>
            <Route path={Path.ASettings} element={<AInformationEnterprise />} />
            {/* CARGOS */}
            <Route
              path={Path.ACargos}
              element={
                <Protections.Global
                  required={user?.user?.type === 'admin'}
                  permission={userPermissions?.cargos_vizualizar}
                >
                  <APositions />
                </Protections.Global>
              }
            />
            <Route
              path={Path.AAddCargos}
              element={
                <Protections.Global required={user?.user?.type === 'admin'} permission={userPermissions?.cargos_criar}>
                  <AAddPositions />
                </Protections.Global>
              }
            />
            <Route
              path={Path.AEditCargos(':id')}
              element={
                <Protections.Global required={user?.user?.type === 'admin'} permission={userPermissions?.cargos_editar}>
                  <AEditPositions />
                </Protections.Global>
              }
            />
            {/* DOCUMENTS */}
            <Route
              path={Path.ADocuments}
              element={
                <Protections.Global
                  required={user?.user?.type === 'admin'}
                  permission={userPermissions?.documentos_vizualizar}
                >
                  <ADocuments />
                </Protections.Global>
              }
            />
            <Route
              path={Path.AAddDocuments}
              element={
                <Protections.Global
                  required={user?.user?.type === 'admin'}
                  permission={userPermissions?.documentos_criar}
                >
                  <AAddDocuments />
                </Protections.Global>
              }
            />
            <Route
              path={Path.AEditDocuments(':id')}
              element={
                <Protections.Global
                  required={user?.user?.type === 'admin'}
                  permission={userPermissions?.documentos_editar}
                >
                  <AEditDocuments />
                </Protections.Global>
              }
            />
            {/* CONSENTS */}
            <Route
              path={Path.AConsents}
              element={
                <Protections.Global
                  required={user?.user?.type === 'admin'}
                  permission={userPermissions?.consentimentos_vizualizar}
                >
                  <AConsents />
                </Protections.Global>
              }
            />
            <Route
              path={Path.AAddConsents}
              element={
                <Protections.Global
                  required={user?.user?.type === 'admin'}
                  permission={userPermissions?.consentimentos_criar}
                >
                  <AAddConsents />
                </Protections.Global>
              }
            />
            <Route
              path={Path.AEditConsents(':id')}
              element={
                <Protections.Global
                  required={user?.user?.type === 'admin'}
                  permission={userPermissions?.consentimentos_editar}
                >
                  <AEditConsents />
                </Protections.Global>
              }
            />
            {/* PLANS */}
            <Route
              path={Path.APlans}
              element={
                <Protections.Global
                  required={user?.user?.type === 'admin'}
                  permission={userPermissions?.planos_vizualizar}
                >
                  <APlans />
                </Protections.Global>
              }
            />
            <Route
              path={Path.AAddPlans}
              element={
                <Protections.Global required={user?.user?.type === 'admin'} permission={userPermissions?.planos_criar}>
                  <AAddPlans />
                </Protections.Global>
              }
            />
            <Route
              path={Path.AEditPlans(':id')}
              element={
                <Protections.Global required={user?.user?.type === 'admin'} permission={userPermissions?.planos_editar}>
                  <AEditPlans />
                </Protections.Global>
              }
            />
            {/* SECTOR */}
            <Route
              path={Path.ASectors}
              element={
                <Protections.Global
                  required={user?.user?.type === 'admin'}
                  permission={userPermissions?.setores_vizualizar}
                >
                  <ASectors />
                </Protections.Global>
              }
            />
            <Route
              path={Path.AAddScetors}
              element={
                <Protections.Global required={user?.user?.type === 'admin'} permission={userPermissions?.setores_criar}>
                  <AAddSectors />
                </Protections.Global>
              }
            />
            <Route
              path={Path.AEditSectors(':id')}
              element={
                <Protections.Global
                  required={user?.user?.type === 'admin'}
                  permission={userPermissions?.setores_editar}
                >
                  <AEditSectors />
                </Protections.Global>
              }
            />
            {/* USERS */}
            <Route
              path={Path.AUsers}
              element={
                <Protections.Global
                  required={user?.user?.type === 'admin'}
                  permission={userPermissions?.usuarios_vizualizar}
                >
                  <AUsers />
                </Protections.Global>
              }
            />
            <Route
              path={Path.AAddUsers}
              element={
                <Protections.Global
                  required={user?.user?.type === 'admin'}
                  permission={userPermissions?.usuarios_criar}
                >
                  <AAddUsers />
                </Protections.Global>
              }
            />
            <Route
              path={Path.AEditUsers(':uid')}
              element={
                <Protections.Global
                  required={user?.user?.type === 'admin'}
                  permission={userPermissions?.usuarios_editar}
                >
                  <AEditUsers />
                </Protections.Global>
              }
            />
          </Route>
        </Route>

        <Route path={Path.ClientHome} element={<ClientLayoutNavBar />}>
          <Route
            path={Path.ClientHome}
            element={
              <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                <ClientHome />
              </Protections.Global>
            }
          />
          <Route path={Path.ClientSettings} element={<ClientLayoutSideBarSettings />}>
            <Route
              path={Path.ClientSettings}
              element={
                <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                  <ClientInfoEnterprise />
                </Protections.Global>
              }
            />
            <Route
              path={Path.ClientUsers}
              element={
                <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                  <ClientUsers />
                </Protections.Global>
              }
            />
            <Route
              path={Path.ClientAddUsers}
              element={
                <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                  <ClientAddUsers />
                </Protections.Global>
              }
            />

            <Route
              path={Path.ClientEditUsers(':uid')}
              element={
                <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                  <ClientEditUsers />
                </Protections.Global>
              }
            />

            <Route
              path={Path.ClientSectors}
              element={
                <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                  <ClientSectors />
                </Protections.Global>
              }
            />
            <Route
              path={Path.ClientAddSectors}
              element={
                <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                  <ClientAddSectors />
                </Protections.Global>
              }
            />
            <Route
              path={Path.ClientEditSectors(':id')}
              element={
                <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                  <ClientEditSectors />
                </Protections.Global>
              }
            />
            <Route
              path={Path.ClientPayments}
              element={
                <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                  <ClientPayments />
                </Protections.Global>
              }
            />
            <Route
              path={Path.ClientInformations}
              element={
                <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                  <ClientInformations />
                </Protections.Global>
              }
            />
            <Route
              path={Path.ClientCitys}
              element={
                <Protections.Global required={user?.user?.type === 'client'} permission={userPermissions?.city_view}>
                  <ClientCitys />
                </Protections.Global>
              }
            />
          </Route>
          <Route
            path={Path.ClientEvents}
            element={
              <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                <ClientEvents />
              </Protections.Global>
            }
          />
          <Route
            path={Path.ClientAddEvents}
            element={
              <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                <ClientAddEvents />
              </Protections.Global>
            }
          />
          <Route
            path={Path.ClientEditEvents(':id')}
            element={
              <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                <ClientLayoutSideBarEvents />
              </Protections.Global>
            }
          >
            <Route
              path={Path.ClientEditEvents(':id')}
              element={
                <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                  <ClientEditEvents />
                </Protections.Global>
              }
            />
            <Route
              path={Path.ClientjobOffersEvents(':id')}
              element={
                <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                  <ClientEventsjobOffers />
                </Protections.Global>
              }
            />
            <Route
              path={Path.ClientStaffEvents(':id')}
              element={
                <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                  <ClientEventsStaff />
                </Protections.Global>
              }
            />
            <Route
              path={Path.ClientHistoryEvent(':id')}
              element={
                <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                  <ClientEventsHistory />
                </Protections.Global>
              }
            />
            <Route
              path={Path.ClientStaffFinally(':id')}
              element={
                <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                  <ClientEventsFinally />
                </Protections.Global>
              }
            />
          </Route>
          <Route
            path={Path.ClientTeams}
            element={
              <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                <ClientTeams />
              </Protections.Global>
            }
          />
          <Route
            path={Path.ClientAddTeams}
            element={
              <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                <ClientAddTeams />
              </Protections.Global>
            }
          />
          <Route
            path={Path.ClientEditTeams(':id')}
            element={
              <Protections.Global required={user?.user?.type === 'client'} permission={true}>
                <ClientEditTeams />
              </Protections.Global>
            }
          />
        </Route>

        {/* NOT FOUND (sempre na última posição) */}
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  )
}

// cada página será um objeto
// {
//     path:"/componentes",
//     element: <Teste_Componentes /> ,
//     errorElement: <error404 />
// },
