import React from 'react'

import { Column } from '../../../../components/layouts/columns'
import { Input } from '../../../../components/ui/input'
import { Select } from '../../../../components/ui/select'
import { Button } from '../../../../components/ui/button'
import { useClientFormEvents } from '../_hooks/useClientFormEvents'
import { TransformationforMask } from '../../../../utils/masks'
import { Checkbox } from '../../../../components/ui/checkbox'
import { Table } from '../../../../components/ui/table'
import { Icon } from '../../../../components/icons'

export const ClientsFormEventsInfosLayout = ({
  loading,
  formContext,
  setFormContext,
  options,
  disabledList,
  clientId,
}) => {
  const { infosContext, vestmentList, HandleAddVestments, HandleDeleteVestments, HandleAddCity, HandleDeleteCity } =
    useClientFormEvents(formContext, setFormContext, options, clientId)

  return (
    <div>
      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Nome do Evento
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'name'}
                required={true}
                disabled={disabledList?.name}
                maskValue={'title'}
                maskSetValue={'lower'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content size="is-3">
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Turno
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'round'}
                disabled={disabledList?.uf}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={'1º turno'} title={'1º Turno'} />
                <Select.Option value={'2º turno'} title={'2º Turno'} />
                <Select.Option value={'3º turno'} title={'3º Turno'} />
                <Select.Option value={'4º turno'} title={'4º Turno'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Tipo do Registro
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'register'}
                disabled={disabledList?.uf}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={'Não Registrado'} title={'Não Registrado'} />
                <Select.Option value={'Registro Intermitente'} title={'Registro Intermitente'} />
                <Select.Option value={'Registro Por Tempo determinado'} title={'Registro Por Tempo determinado'} />
                <Select.Option value={'Registro Por Tempo indeterminado'} title={'Registro Por Tempo indeterminado'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Pagamento
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'payment'}
                disabled={disabledList?.uf}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={'pix'} title={'Pix'} />
                <Select.Option value={'dinheiro'} title={'Dinheiro'} />
                <Select.Option value={'transferencia'} title={'Transferência Bancaria'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Data Pagamento
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'dtPayment'}
                required={true}
                disabled={disabledList?.dtPayment}
                type="date"
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Endereço
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'endereco'}
                required={true}
                disabled={disabledList?.endereco}
                maskValue={'title'}
                maskSetValue={'lower'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content size="is-3">
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Cidade/Municipio
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'city'}
                required={true}
                disabled={disabledList?.city}
                maskValue={'title'}
                maskSetValue={'lower'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
        <Column.Content size="is-2">
          <Input.Root className="">
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              UF
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'uf'}
                disabled={disabledList?.uf}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={'AC'} title={'AC'} />
                <Select.Option value={'AL'} title={'AL'} />
                <Select.Option value={'AP'} title={'AP'} />
                <Select.Option value={'AM'} title={'AM'} />
                <Select.Option value={'BA'} title={'BA'} />
                <Select.Option value={'CE'} title={'CE'} />
                <Select.Option value={'DF'} title={'DF'} />
                <Select.Option value={'ES'} title={'ES'} />
                <Select.Option value={'GO'} title={'GO'} />
                <Select.Option value={'MA'} title={'MA'} />
                <Select.Option value={'MT'} title={'MT'} />
                <Select.Option value={'MS'} title={'MS'} />
                <Select.Option value={'MG'} title={'MG'} />
                <Select.Option value={'PA'} title={'PA'} />
                <Select.Option value={'PB'} title={'PB'} />
                <Select.Option value={'PR'} title={'PR'} />
                <Select.Option value={'PE'} title={'PE'} />
                <Select.Option value={'PI'} title={'PI'} />
                <Select.Option value={'RJ'} title={'RJ'} />
                <Select.Option value={'RN'} title={'RN'} />
                <Select.Option value={'RS'} title={'RS'} />
                <Select.Option value={'RO'} title={'RO'} />
                <Select.Option value={'RR'} title={'RR'} />
                <Select.Option value={'SC'} title={'SC'} />
                <Select.Option value={'SP'} title={'SP'} />
                <Select.Option value={'SE'} title={'SE'} />
                <Select.Option value={'TO'} title={'TO'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Local de Encontro
            </Input.Label>
            <Input.Contents>
              <Input.Prompt
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'local_encontro'}
                required={true}
                disabled={disabledList?.cpf}
                maskValue={'title'}
                maskSetValue={'lower'}
              ></Input.Prompt>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>

      <Column.Root>
        <Column.Content className="is-5">
          <Checkbox.Root text="Habilitar Restrição de Candidaturas por Cidades">
            <Checkbox.Content
              value={formContext}
              setValue={setFormContext}
              field={'hasCityRestrictions'}
            ></Checkbox.Content>
          </Checkbox.Root>
        </Column.Content>
      </Column.Root>

      {formContext?.hasCityRestrictions && (
        <div className="box mb-6">
          <Column.Root>
            {!formContext?.newCity ? (
              <Column.Content>
                <Input.Root>
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    Escolha as cidades permitidas:
                  </Input.Label>
                  <Select.Root>
                    <Select.Content
                      color="is-grey"
                      className="is-fullwidth is-rounded"
                      value={formContext}
                      setValue={setFormContext}
                      field={'selectCityRestrictions'}
                      disabled={disabledList?.vestments}
                    >
                      <Select.Option value={null} title={''} />
                      {(infosContext?.citys || []).map((value) => {
                        return <Select.Option key={value.id} value={value.id} title={`${value.city} - ${value.uf}`} />
                      })}
                    </Select.Content>
                  </Select.Root>
                </Input.Root>
              </Column.Content>
            ) : (
              <Column.Content>
                <Input.Root>
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                    Digite o CEP para cadastrar um cidade permitida
                  </Input.Label>
                  <Input.Contents>
                    <Input.Prompt
                      value={formContext}
                      setValue={setFormContext}
                      field={'inputCEP'}
                      maskValue="cep"
                    ></Input.Prompt>
                  </Input.Contents>
                </Input.Root>
              </Column.Content>
            )}
            <Column.Content size="is-3 mt-5">
              <Button.Root align="centered">
                <Button.Content>
                  <Button.Bt
                    color="is-dark"
                    className={'is-rounded'}
                    loading={loading}
                    action={() => {
                      HandleAddCity()
                    }}
                  >
                    Adicionar
                  </Button.Bt>
                </Button.Content>
                <Button.Content>
                  <Button.Bt
                    color="is-success"
                    className={'is-rounded has-text-white'}
                    loading={loading}
                    action={() => {
                      setFormContext((prev) => ({ ...prev, newCity: !prev?.newCity }))
                    }}
                  >
                    {formContext?.newCity ? 'Selecionar' : 'Digitar CEP'}
                  </Button.Bt>
                </Button.Content>
              </Button.Root>
            </Column.Content>
          </Column.Root>
          <Table.Root className="is-fullwidth is-hoverable is-narrow has-text-centered">
            <Table.Head>
              <Table.Row>
                <Table.Cell>Cidade</Table.Cell>
                <Table.Cell>UF</Table.Cell>
                <Table.Cell>Ações</Table.Cell>
              </Table.Row>
            </Table.Head>
            <Table.Body>
              {(formContext?.cityRestrictions || []).map((value) => {
                console.log(value)
                return (
                  <>
                    <Table.Row>
                      <Table.Cell className="is-size-7 is-vcentered">{value.city}</Table.Cell>
                      <Table.Cell className="is-size-7 is-vcentered">{value.uf}</Table.Cell>
                      <Table.Cell className="is-vcentered">
                        <div style={{ marginBottom: 10, marginTop: 5 }}>
                          <Button.Root align="centered">
                            <Button.Content>
                              <Button.Bt
                                size="is-small"
                                color="is-danger"
                                className={'has-text-white'}
                                action={() => {
                                  HandleDeleteCity(value)
                                }}
                                loading={loading}
                              >
                                <Icon size={15}>trash</Icon>
                              </Button.Bt>
                            </Button.Content>
                          </Button.Root>
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  </>
                )
              })}
            </Table.Body>
          </Table.Root>
        </div>
      )}

      <Column.Root>
        <Column.Content className="is-5">
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Alimentação
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'food'}
                disabled={disabledList?.food}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={0} title={'Não Fornece'} />
                <Select.Option value={1} title={'Fornece'} />
                <Select.Option value={2} title={'Incluso no cachê'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
        {formContext?.food === '2' && (
          <Column.Content>
            <Input.Root>
              <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                Valor da alimentação
              </Input.Label>
              <Input.Contents alignIcon={'left'}>
                <Input.Prompt
                  className="is-rounded"
                  value={formContext}
                  setValue={setFormContext}
                  field={'foodValue'}
                  required={true}
                  disabled={disabledList?.foodValue}
                  type="number"
                ></Input.Prompt>
                <Input.Icon align="is-left">R$</Input.Icon>
              </Input.Contents>
            </Input.Root>
          </Column.Content>
        )}
      </Column.Root>

      <Column.Root>
        <Column.Content className="is-5">
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Vale Transporte
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'transportAllowance'}
                disabled={disabledList?.transportAllowance}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={0} title={'Não Fornece'} />
                <Select.Option value={1} title={'Fornece'} />
                <Select.Option value={2} title={'Incluso no cachê'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
        {formContext?.transportAllowance === '2' && (
          <Column.Content>
            <Input.Root>
              <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
                Valor do Vale Transporte
              </Input.Label>
              <Input.Contents alignIcon={'left'}>
                <Input.Prompt
                  className="is-rounded"
                  value={formContext}
                  setValue={setFormContext}
                  field={'transportAllowanceValue'}
                  required={true}
                  disabled={disabledList?.transportAllowanceValue}
                  type="number"
                ></Input.Prompt>
                <Input.Icon align="is-left">R$</Input.Icon>
              </Input.Contents>
            </Input.Root>
          </Column.Content>
        )}
      </Column.Root>

      <Column.Root>
        <Column.Content className="is-5">
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Vestimenta
            </Input.Label>
            <Select.Root>
              <Select.Content
                color="is-grey"
                className="is-fullwidth is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'vestments'}
                disabled={disabledList?.vestments}
                required={true}
              >
                <Select.Option value={null} title={''} />
                <Select.Option value={0} title={'Não Fornece'} />
                <Select.Option value={1} title={'Fornece'} />
              </Select.Content>
            </Select.Root>
          </Input.Root>
        </Column.Content>
        {formContext?.vestments === '1' && (
          <Column.Content>
            <Column.Root>
              <Column.Content>
                <Input.Root>
                  <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }}>
                    Qual parte será fornecida?
                  </Input.Label>
                  <Select.Root>
                    <Select.Content
                      color="is-grey"
                      className="is-fullwidth is-rounded"
                      value={formContext}
                      setValue={setFormContext}
                      field={'selectVestment'}
                      disabled={disabledList?.vestments}
                    >
                      <Select.Option value={''} title={'Selecione uma vestimenta...'} />
                      {vestmentList
                        .filter((vest) => {
                          return !formContext?.submitVestments?.includes(vest)
                        })
                        .sort((a, b) => {
                          return a.localeCompare(b)
                        })
                        .map((vest) => {
                          return <Select.Option key={vest} value={vest} title={TransformationforMask(vest, 'title')} />
                        })}
                    </Select.Content>
                  </Select.Root>
                </Input.Root>
              </Column.Content>
              <Column.Content size="is-2">
                <Button.Bt
                  className="mt-5 is-rounded"
                  color="is-dark"
                  loading={loading}
                  action={() => {
                    HandleAddVestments(formContext?.selectVestment)
                  }}
                >
                  Adicionar
                </Button.Bt>
              </Column.Content>
            </Column.Root>
          </Column.Content>
        )}
      </Column.Root>

      {formContext?.vestments === '1' && (
        <div className="mx-2 mb-5">
          <Column.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={true}>
              Vestimentas fornecidas
            </Input.Label>
          </Column.Root>
          <Column.Root className={'mx-5 mt-2'}>
            <div className="tags is-light is-medium is-rounded">
              {formContext?.submitVestments?.length > 0 &&
                formContext.submitVestments.map((vestment) => (
                  <span className="tag is-success is-rounded" key={vestment}>
                    {TransformationforMask(vestment, 'title')}
                    <button
                      className="delete is-small"
                      onClick={() => {
                        HandleDeleteVestments(vestment)
                      }}
                    ></button>
                  </span>
                ))}
            </div>
          </Column.Root>
        </div>
      )}

      <Column.Root className="mt-5">
        <Column.Content>
          <Input.Root>
            <Input.Label className="ml-3 has-text-grey" style={{ marginBottom: 0 }} required={false}>
              Informações Adicionais
            </Input.Label>
            <Input.Contents>
              <Input.Textarea
                className="is-rounded"
                value={formContext}
                setValue={setFormContext}
                field={'infoAdditional'}
                required={false}
                disabled={disabledList?.infoAdditional}
                maskValue={'title'}
                maskSetValue={'lower'}
              ></Input.Textarea>
            </Input.Contents>
          </Input.Root>
        </Column.Content>
      </Column.Root>
    </div>
  )
}
