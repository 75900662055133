import React from 'react'

import { Page } from '../../../components/layouts/page'
import { Column } from '../../../components/layouts/columns'
import { Form } from '../../../components/ui/form'
import { ClientsLayout } from './_form/clientsLayout'
import { useAddClients, useAdminClients } from './_hooks'

export const AAddClients = () => {
  const { navigate } = useAdminClients()
  const { loading, setLoading, infosContext, formContext, setFormContext, FetchAddNewClient } = useAddClients()

  return (
    <Page.Section className="is-fullheight-with-navbar mt-6 pt-6">
      <Form.Root
        action={() => {
          FetchAddNewClient()
        }}
        className="mt-5"
      >
        <Column.Root>
          <Column.Content>
            <p className="subtitle is-size-3">Adicionar Novo Cliente</p>
          </Column.Content>
        </Column.Root>
        <ClientsLayout
          navigate={navigate}
          loading={loading}
          setLoading={setLoading}
          values={formContext}
          setValues={setFormContext}
          options={infosContext}
          disabledList={{}}
        />
      </Form.Root>
    </Page.Section>
  )
}
