import { Icon } from '../../../../components/icons'
import { Button } from '../../../../components/ui/button'
import { Modal } from '../../../../components/ui/modal'
import { ValidateCollaboratorModalForm } from '../_components/validateCollaboratorModalForm'
import { useValidateCollaboratorModal } from '../_hooks/useValidateCollaboratorModal'

export const ValidateCollaboratorModal = ({ active, setActive }) => {
  const { handleSendFieldsToFix } = useValidateCollaboratorModal()

  return (
    <Modal.Root active={active}>
      <Modal.Contents>
        <form>
          <Modal.Header>
            <p className="modal-card-title" style={{ marginBottom: -15 }}>
              Validação dos campos
            </p>
            <Modal.HeaderClose setActive={setActive}></Modal.HeaderClose>
          </Modal.Header>
          <Modal.Body>
            <ValidateCollaboratorModalForm />
          </Modal.Body>
          <Modal.Footer className="is-justify-content-end">
            {/* {console.log(values?.favorite)} */}
            <Button.Bt
              // color={values?.favorite ? 'is-danger' : 'is-light'}
              type="button"
              colorText="has-text-white"
              color="is-success"
              title="Favoritar"
              action={handleSendFieldsToFix}
            >
              Enviar campos para correção
              <Icon size={20}>check</Icon>
            </Button.Bt>
          </Modal.Footer>
        </form>
      </Modal.Contents>
    </Modal.Root>
  )
}
